<template>
  <div>
    <a-drawer
      placement="bottom"
      :closable="false"
      :visible="visible"
      height="50%"
      @close="onClose"
    >
      <div class="payTip_title">选择支付方式</div>
      <img
        @click="onClose"
        class="payTip_close"
        src="/asserts/common/close.png"
      />
      <div class="payTip_money">
        <span class="payTip_money_span1"
          >¥<span class="payTip_money_span11">{{
            willPay | num_format(2)
          }}</span></span
        >
      </div>
      <div class="payTip_line payTip_line_1" @click.stop="selectPayStyle(0)">
        <img class="payTip_icon" src="/asserts/common/balance.png" alt="" />
        <span class="payTip_line_name">账号余额</span>
        <div class="payTip_line_style">
          <span class="payTip_line_style_bal1"
            >¥<span class="payTip_line_style_bal11">{{
              balance
            }}</span></span
          >
          <span
            :class="[
              payType == 0 ? 'selected_pay_style' : 'unselected_pay_style',
            ]"
            >&nbsp;</span
          >
        </div>
      </div>
      <div class="payTip_line" @click.stop="selectPayStyle(1)">
        <img class="payTip_icon" src="/asserts/common/wechat.png" alt="" />
        <span class="payTip_line_name">微信支付</span>
        <div class="payTip_line_style">
          <span
            :class="[
              payType == 1 ? 'selected_pay_style' : 'unselected_pay_style',
            ]"
            >&nbsp;</span
          >
        </div>
      </div>
      <img
        class="pay_btn"
        src="/asserts/common/pay_btn.png"
        @click="createOrder"
      />
    </a-drawer>
  </div>
</template>

<script>
import {errorMsg, warningMsg} from "./prompt-message/js/TipMessage.js";
export default {
  name: "PayTip",
  props: ["willPay"],
  data() {
    return {
      balance: 0,
      payType: 0,
      visible: false,
    };
  },
  created() {
    this.init_balance();
  },
  methods: {
    init_balance() {
      this.axios.post(this.baseUrl + "/user/account/balance", "", {
          headers: { token: localStorage.getItem("token") },
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.code == 1 && res.data.data != null) {
              this.balance = res.data.data;
            } else {
              warningMsg(res.data.message);
            }
          } else {
            errorMsg("获取账户信息失败!");
          }
        }).catch((err) => {
          errorMsg("系统异常，请联系客服人员!");
        });
    },
    selectPayStyle(type) {
      this.payType = type;
    },
    onClose() {
      this.visible = false;
    },
    createOrder() {
      this.$emit("create-order", this.payType, this.balance);
      //支付完毕关闭支付弹框
      this.onClose();
    },
  },
};
</script>

<style scoped>
.payTip_title {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}
.payTip_close {
  float: right;
  width: 0.8rem;
  position: relative;
  top: -1.2rem;
}
.payTip_money {
  text-align: center;
  margin-top: 4%;
  color: #ff573a;
  font-weight: 600;
}
.payTip_money_span1 {
  font-size: 1.4rem;
}
.payTip_money_span11 {
  font-size: 2rem;
  margin-left: 0.2rem;
}
.payTip_line {
  padding: 4% 0;
}
.payTip_line_1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.payTip_line_name {
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
  margin-left: 0.5rem;
}
.payTip_line_style {
  float: right;
}

.payTip_icon {
  width: 1rem;
  position: relative;
  top: -0.1rem;
}
.payTip_line_style_bal1 {
  padding-right: 0.2rem;
  font-size: 0.7rem;
  color: #ff573a;
  font-weight: 600;
}
.payTip_line_style_bal11 {
  font-size: 1rem;
  margin-left: 0.1rem;
}
.selected_pay_style {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("../asserts/common/pay_select.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 0.1rem;
}
.unselected_pay_style {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-image: url("../asserts/common/pay_unselect.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 0.1rem;
}
.pay_btn {
  margin-top: 14%;
  width: 100%;
}
</style>