//判断手机号格式是否正确
let checkPhone = (phone)=>{
    var reg = /^1[3456789]\d{9}$/;
      if (phone.length === 0 || phone.length != 11 || !reg.test(phone)) {
        return false;
      }
      return true;
}

//判断是否为空
let isNull = (param)=>{
    if(param == "" || param == null || param.length == 0 || 
    typeof(param) == 'undefined'){
        return true;
    }
    return false;
}

//校验验证码
let checkVerifyCode = (verifyCode)=>{
    var reg = /^[0-9]{6}$/;
    if (!reg.test(verifyCode)) {
      return false;
    }
    return true;
  }
export { checkPhone, isNull, checkVerifyCode }