<template>
<div>
  <swiper ref="swiperD" :options="swiperOption">
        <swiper-slide v-for="item in devices" :key="item.iccid" >
            <div :class="['device_info', 'device_'+item.imei, item.imei==curIMEI?'select_device':'unselect_device']" v-if="!innerShowDefault">
                <span class="ssid_span">{{item.ssid}}</span><br>
                <span class="simall_span">IMEI: {{item.imei | str_format(4)}}</span><br>
                <!-- <span class="simall_span simall_span_last" v-if="item.iccid!=null && item.status == 0">ICCID:{{item.iccid==null?'':item.iccid | str_format(4)}}</span>
                <span class="simall_span simall_span_last" v-if="item.iccid!=null && item.status == 1"  @click.stop="goRealname(item.imei, item.iccid)"><span class="go_realname">实名中></span>{{item.iccid==null?'':item.iccid | str_format(4)}}</span>
                <span class="simall_span simall_span_last go_realname" v-if="item.iccid==null" @click.stop="go_realname(item.imei)"> 去实名 ></span> -->
            </div>
            <div :class="['device_info', 'device_'+item.imei, item.imei==curIMEI?'select_device':'unselect_device']" v-if="innerShowDefault">
                <span class="ssid_span">添加设备</span><br>
                <span class="simall_span">绑定设备并实名之后再购买国内套餐</span><br>
                <span class="simall_span simall_span_last go_realname" @click.stop="go_realname('')"> 去绑定 ></span>
            </div>
        </swiper-slide>
      <swiper-slide v-if="useFinalSlid">
          <div :class="['device_info', (curIMEI==111)?'select_device':'unselect_device']">
            <div class="ssid_div">
              <input class="swiper_final_input" type="text" v-model="tsidImei" @input="bindDevice" :v-text="scanImei" placeholder="请输入设备的TSID/IMEI号" >
              <img class="scan_img" src="/asserts/common/scan.png" @click="scanTSID">
            </div><br>
            <div class="alert_div">
              <img class="simal_alert" src="/asserts/common/alert.png" alt="">
              <span class="simall_span1">{{alertMsg}}</span>
            </div>
          </div>
      </swiper-slide>
  </swiper>
</div>
    
</template>

<script>
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { errorMsg, successMsg, warningMsg} from './prompt-message/js/TipMessage'
import { isNull } from './VerifyParamUtil';
 
export default {
  name: 'SwiperModel',
  props: [
    // 'devices', 
    'alertMsg',
    'useFinalSlid',
    'flag',
    'paramImei',  //请求后端的参数
    'paramIccid', //请求后端的参数
  ],
  data(){
    return {
      // alertMsg: '流量查询: 可扫描设备二维码(打开设备前盖可见)进行流量查询',
      devices: [],
      curIMEI: '',
      tsidImei: "",
      curICCID: '',
      active: null,
      scanImei: '',
      timer: null,  //定时器
      innerShowDefault: false,
      swiperOption: {
        slidesPerView: 1.3,
        initialSlide: 0,
        centeredSlides: true,
        paginationClickable: true,
        slideToClickedSlide: true,
        spaceBetween: 15,
        autoplay: {
          delay: 2000,
        },
        on: {
          slideChangeTransitionEnd: null
        }
      }
    }
  },
  async created() {
    this.init_swiperModel()
    await this.init_device()
    this.init_selected()
  },
  computed: {
    mySwiper: function(){
       return this.$refs.swiperD.$swiper;
    }
  },
  methods: {
    async init_device(){
        //初始化流量查询数据
       if(this.flag == 1){
        await this.axios.post(this.baseUrl + "/device/toFlowSearch?imei="+this.paramImei + "&iccid="+this.paramIccid, "", {headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}).then((res) => {
            if(res.status == 200){
                var datas = res.data.data
                if(res.data.code == 1){
                  if(datas != null){
                    if(datas.devices != null && datas.devices.length > 0){
                        if(datas.devices[0].imei == '862716123456789'){
                          this.devices = []
                        }else{
                          this.devices = datas.devices
                        }
                    }
                    this.$emit('init-data', datas)
                  }
                }else if(res.data.code == 5){
                  this.$router.push({name: 'login'})
                }else{
                  warningMsg(res.data.message)
                }
              }else{
                history.go(-1)
              }
          }).catch((err) => {
              this.$router.push({name: 'error'})
          });
       }else if(this.flag == 2){  //初始化国际套餐购买
         await this.axios.post(this.baseUrl + "/order/outer/flowPacket?imei="+this.paramImei + "&iccid="+this.paramIccid, "", {headers: { token: localStorage.getItem("token"),
                  openId: localStorage.getItem("openId")}}).then((res) => {
                if(res.status == 200){
                  if(res.data.code == 1) {
                      if(res.data.data != null){
                        this.devices = res.data.data.devices
                      if(this.devices[0].imei == '862716123456789'){
                        this.devices = []
                      }
                        this.$emit('init-data', res.data.data)
                      }
                  }else if(res.data.code == 5){
                    this.$router.push({name: 'login'})
                  }else{
                    history.go(-1)
                  }
                }else{
                  history.go(-1)
                }
              }).catch((err) => {
                this.$router.push({name: "error"})
              });
       }else if(this.flag == 3){ //初始化国内套餐购买
         await this.axios.post(this.baseUrl + "/order/inner/flowPacket", "", {headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}).then((res) => {
                if(res.status == 200){
                  if(res.data.code == 1 && res.data.data != null){
                    this.devices = res.data.data.devices
                    if(this.devices[0].imei == '862716123456789'){
                        this.innerShowDefault = true
                    }
                    this.$emit('init-data', res)
                  }else if(res.data.code == 5){
                    this.$router.push({name: 'login'})
                  }else{
                    warningMsg("没有可购买的套餐信息！")
                    this.$router.push({name: "myDevice"});
                  }
                }else{
                  this.$router.push({ name: "error" });
                }
            }).catch((err) => {
              this.$router.push({ name: "error" });
            });
       }
     },
      init_selected(){
         if(this.devices != null && this.devices.length > 0){
          // 1、查找初始化数据中被选中设备的下标，没有选中的会返回-1
           var initIndex = this.devices.findIndex(item=>{
             if(item.selected == 1){
                this.curIMEI = item.imei
                if(item.iccid != null && item.iccid != '' && item.iccid.length > 0){
                this.curICCID = item.iccid
               }
               return true;
             }
           })
          if(initIndex == -1){
            this.curIMEI = 111
            this.curICCID = ""
          }else{
            this.mySwiper.slideTo(initIndex, 500,false);
          }
       }else{
          this.curIMEI = 111
          this.curICCID = ""
       }
      },
      init_swiperModel(){
        if(this.devices==null || this.devices.length == 0){
           this.curIMEI = 111
           this.curICCID = ""
        }
  
      //4、切换swiper时触发(切换选中设备)
      this.swiperOption.on.slideChangeTransitionEnd = function(swiper) {
        this.active = swiper.realIndex; // 当前选中的块 
        if(this.active >= 0 && this.active < this.devices.length){
          var item = this.devices[this.active]
          this.curIMEI = item.imei
          if(item.iccid != null && item.iccid != '' && item.iccid.length > 0){
            this.curICCID = item.iccid
          }
        }else{
          this.curIMEI = 111
          this.curICCID = ""
        }
        
        // 修改选中设备(之前选中的改为未选中，当前的改为选中)
        this.changeSelected(this.curIMEI);
        
        // 请求后端获取选中设备的数据
        this.callbackParentMethod(this.curIMEI, this.curICCID)//当前的imei和iccid  iccid可以是空值
      }.bind(this);
    },
    //组件切换时需要回调的方法(设备切换后，回调父组件对应的方法。)
    callbackParentMethod(imei, iccid){
        this.$emit('get-countrys', imei, iccid)
    },
    changeSelected(imei){
      //将之前选中的设备修改成未选中
        this.devices.filter(item => {
          if (item.selected == 1) {
            item.selected = 0;
          }
        })
      //将当前的改为已选中
      if(imei != 111){
        this.devices.filter(item => {
          if (item.imei == imei) {
            item.selected = 1;
            return;
          }
        })
      }
    },

    scanTSID(){
      var currentThis = this
      this.$wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var imei = res.resultStr.split(",")[1]; // 当needResult 为 1 时，扫码返回的结果
          var imei2 = res.resultStr.substr(res.resultStr.lastIndexOf(":")+1)
          // imei扫描结果校验
  
          if(isNull(imei) && isNull(imei2)){
              warningMsg("请重新扫描设备")
              return;
          }
          imei = isNull(imei)?imei2:imei
          currentThis.scanImei = imei
          currentThis.axios.post(currentThis.baseUrl + "/device/scan/bindDevice?imei="+imei+"&flag="+currentThis.flag, "", {
          headers: { token: localStorage.getItem("token") },
          }).then((res) => {
            if (res.status == 200 && res.data.code == 1 ) {
              currentThis.scanImei = ""
              currentThis.tsidImei = ""
              if(res.data.data != null){
                currentThis.devices.push(res.data.data)
                currentThis.changeSelected(res.data.data.imei)
                currentThis.curIMEI = res.data.data.imei
                currentThis.callbackParentMethod(imei, null)
              }else{
                  var initIndex = currentThis.devices.findIndex(item=>{
                    if(item.imei == imei){
                       currentThis.curIMEI = item.imei
                       if(item.iccid != null && item.iccid != '' && item.iccid.length > 0){
                        currentThis.curICCID = item.iccid
                        }
                      return true;
                    }
                  })
                  currentThis.changeSelected(imei)
                  currentThis.mySwiper.slideTo(initIndex, 500, false)
                  currentThis.callbackParentMethod(imei, null)
              }
            }else{
              warningMsg(res.data.message)
            }
        }).catch((err) => {
          currentThis.$router.push({ name: "error" });
        });
        },
        error: function (res) {
          errorMsg(res)
        },
      });
    },
    bindDevice(){
        clearTimeout(this.timer)
        if((this.tsidImei.length == 9 || this.tsidImei.length == 15) && this.scanImei != this.tsidImei){ 
          this.timer = setTimeout(() => {
               this.axios.post(this.baseUrl + "/device/scan/bindDevice?imei="+this.tsidImei+"&flag="+this.flag, "", {
                headers: { token: localStorage.getItem("token") },
                }).then((res) => {
                  if (res.status == 200) {
                    if(res.data.code == 1 ){
                      if(res.data.data != null){
                        this.devices.push(res.data.data)
                        this.changeSelected(res.data.data.imei)
                        this.curIMEI = res.data.data.imei
                        this.callbackParentMethod(res.data.data.imei, null)
                        this.tsidImei = ""
                      }else{
                         var initIndex = this.devices.findIndex(item=>{
                            if(item.tsid == this.tsidImei || item.imei == this.tsidImei){
                                this.curIMEI = item.imei
                                if(item.iccid != null && item.iccid != '' && item.iccid.length > 0){
                                  this.curICCID = item.iccid
                                  }
                              return true;
                            }
                          })
                        this.changeSelected(this.curIMEI)
                        this.mySwiper.slideTo(initIndex, 500, false)
                        this.callbackParentMethod(this.curIMEI, null)
                        this.tsidImei = ""
                      }
                  }else if(res.data.code == 400201 && this.tsidImei.length == 9){
                    warningMsg("设备(tsid)" + this.tsidImei + "不存在")
                  }else if(res.data.code == 400201 && this.tsidImei.length == 15){
                    warningMsg(res.data.message) 
                  }else{
                    warningMsg(res.data.message) 
                  }
                }else{
                  warningMsg(res.data.message)
                }
              }).catch((err) => {
                this.$router.push({ name: "error" });
              });
          }, 1500);
        
        }
    },
     go_realname(curImei) {
      // if(curImei == "862716123456789"){
      //   warningMsg("默认设备不可以实名认证，请先绑定设备！")
      //   return;
      // }
      this.$router.push({ name: "realName", params: { imei: curImei } });
    },
    goRealname(curImei, curIccid) {
      this.$router.push({ name: "realName", params: { imei: curImei, iccid: curIccid } });  
    }
  },
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>


<style scoped>
  .device_info{
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      padding-left: 1rem;
      height: 6.5rem;
  }
  .select_device{
      background-image: url("../asserts/common/slipe_select.png");
  }
  .unselect_device{
      background-image: url("../asserts/common/slipe_unselect.png");
  }
  .ssid_span{
      display: inline-block;
      color: #333;
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 1.4rem;
  }
  .ssid_div{
    display: inline-block;
    width: 95%;
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0.9rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }
  .simall_span{
      display: inline-block;
      color: #666666;
      font-size: 0.9rem;
      font-weight: 500;
      margin-top: 0.5rem;
  }
  .simall_span_last{
    position: relative;
    top: -0.3rem;
    padding-bottom: 0.5rem;
  }

  .alert_div{
    display: inline-flex;
    margin-top: 0.7rem;
    margin-bottom: 0.5rem;
  }
  .simal_alert{
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 0.1rem;
  }
  .simall_span1{
    font-size: 0.7rem;
    font-weight: normal;
    margin-left: 0.3rem;
    width: 85%;
    color: #666;
  }

  .swiper_final_input{
    width: 80%;
    border: none;
    font-weight: normal;
    font-size: 0.9rem;
    outline: none;
  }
  .scan_img{
    width: 1.2rem;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
  }
  .go_realname {
  color: #2c82ff;
}
</style>