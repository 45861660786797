<template>
    <div>
        <p>获取数据失败
        </p>
    </div>
</template>

<script>
export default {
    data:{

    }
}
</script>