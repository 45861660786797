<template>
    <div>
        <div style="width:92%; margin: 0 auto;"  v-if="this.selectPacket" class="contain">
            <p>套餐</p>
            <div class="packets_div">
                <div :class="['packet_div', item.detailId==selectPacket.detailId?'select_div':'unselect_div']" v-for="item in packetlists" :key="item.detilId" @click="changeSelected(item)">
                    <span class="name_span">{{item.name}}</span><br>
                    <span class="price_span">售价:¥{{item.price}}</span>
                </div>
            </div>
            <div class="order_detail">
                <p class="order_detail_p">订单详情</p>
                <div class="detail_line detail_line_first">
                    <span class="detail_name">选择套餐</span>
                    <div class="detail_op">
                        <span>{{selectPacket.name}}</span>
                    </div>
                </div>
                <div class="detail_line">
                    <span class="detail_name">套餐数量</span>
                    <div class="detail_op">
                        <img src="/asserts/common/del.png" @click="delCount">
                        <span class="detail_count">{{packetCount}}</span>
                        <img src="/asserts/common/add.png" @click="addCount">
                    </div>
                </div>
                <div class="detail_line">
                    <span class="detail_name">天数总和</span>
                    <div class="detail_op">
                        <span>{{selectPacket.days * packetCount}}</span>
                    </div>
                </div>
            </div>

            <p class="cover_p">覆盖范围</p>
            <div class="cover_div" v-for="item in this.selectPacket.curOver" :key="item.continentname">
                <span class="continent_span">{{item.continentname}}:</span>
                <span class="countries_span">{{item.countrynames}}</span>
            </div>
            
            <div class="certi_div">
                <img class="certi_ins_img" src="/asserts/common/attention.png" >
                <div class="certi_info">
                    <ul v-for="item in attations" :key="item">
                        <li>{{item}}</li>
                    </ul>
                </div>
                
            </div>
        </div>
        <div class="pay_div">
            <span class="pay_span">合计: <span class="pay_money">¥{{willPay | num_format(2)}}</span></span>
            <a class="pay_a" @click.prevent="toPay" v-if="imei != '862716123456789'"><img class="go_pay_btn" src="/asserts/common/goPay.png"></a>
            <a class="pay_a" v-if="imei == '862716123456789'"><img class="go_pay_btn" src="/asserts/common/not_pay.png"></a>
        </div>
        <pay-tip ref="paytip" :will-pay="willPay" @create-order="createOrder"></pay-tip>
    </div>
</template>

<script>
import PayTip from '../components/PayTip.vue'
import { warningMsg, errorMsg } from '../components/prompt-message/js/TipMessage';

export default {
  components: { PayTip },
    data() {
        return {
            selectPacket: null,
            packetCount: 1,
            allDay: 0,
            willPay: 0,
            imei: "",
            packetlists:[],
                // {
                //     packetId: 4,
                //     detailId: 5,
                //     name: '15GB/30天',
                //     price: '430.0',
                //     days: 30,
                //     curOver:[] 流量包覆盖范围
                // }
            // curOver:[
            //     "亚洲:阿联酋、阿曼、阿塞拜疆、巴基斯坦、巴林、东帝汶、菲律宾、格鲁吉亚、哈萨克斯坦、韩国、吉尔吉斯斯坦、中国、中国香港",
            //     "欧洲:阿尔巴尼亚、爱尔兰、爱沙尼亚、奥地利、白俄罗斯",
            //     "非洲:埃及、刚果、加纳、加蓬、留尼汪、乌干达",
            //     "南美洲:阿根廷、巴拉圭、巴拿马、巴西、厄瓜多尔",
            //     "北美洲:加拿大、美国、墨西哥",
            //     "大洋洲:澳大利亚、巴布亚新几内亚、斐济群岛、汤加、新西兰"
            // ],
            attations: [
                // '该流量包在您到达目的地后首次使用时自动激活,激活后立即生效并计时,每24小时为1天;',
                // '自生效之时起, 在所订购的连续N天有效(N等于您订购的天数); 如订购7天, 则流量包在开始生效时计时, 7*24小时后失效',
                // '使用优先级: 已激活的流量包优先使用; 本地流量包比通用流量包优先使用;通用流量包中支持国家少的比支持国家多的优先;',
                // '达量停用说明: 流量包的限定流量用完后将自动激活支持该国使用的其他流量包,如果没有支持该国使用的流量包,将停止服务;',
                // '该流量包在您到达目的地后首次使用时自动激活, 激活后立即生效并开始计时, 每24小时为1天;',
                // '退订说明: 流量包订购后90天内未生效的可退定, 购买时支付的全额费用原路退回您的账号'
            ]
        }
    },
    created() {
        this.init();
    },
    methods: {
        init(){
            this.imei = this.$route.params.imei;
            var countrycode = this.$route.params.countryCode;
            this.axios.post(this.baseUrl + "/order/country/packetDetail?imei="+this.imei+"&countryCode="+countrycode, "",{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}).then((res) => {
                if(res.status == 200){
                    if(res.data.code == 1 && res.data.data != null && res.data.data.length > 0){
                        this.packetlists = res.data.data
                        this.changeSelected(this.packetlists[0])
                    }else if(res.data.code == 5){
                        this.$router.push({name: 'login'})
                    }else{
                        history.go(-1)
                    }
                }else{
                   history.go(-1)
                }
            }).catch((err) => {
                this.$router.push({name: 'error'})
            });
        },
        changeSelected(item){
            //1. 修改选中的套餐数据
            if(this.selectPacket != item){
                this.selectPacket = item
                this.packetCount = 1
                this.calculationWillPay()
                this.packetDescriptDeal(item.explain)  //使用须知
            }
        },
        calculationWillPay(){
            this.willPay = this.selectPacket.price * this.packetCount
        },
        delCount(){
            if(this.packetCount==1){
                warningMsg('最少购买数量为1')
            }else{
                this.packetCount--
                this.calculationWillPay()
            }
        },
        addCount(){
            this.packetCount++;
            this.calculationWillPay()
        },
        toPay(){
            this.$refs.paytip.visible = true
        },
         packetDescriptDeal(explain){
            if(explain.length>0){
                if(explain.indexOf("<br>")>0){
                    var one = explain.split("<br>");
                    if(one[0].length <= 5){
                        one = one.slice(1)
                    }
                    this.attations = one;
                }else{
                    this.attations.push(explain)
                }
			}
        },
        createOrder(payType, balance){
             //校验数据
             if(!(payType === 0 || payType === 1)){
                 warningMsg("购买失败，支付方式不正确！")
                 return;
             } 
            if(payType === 0 && Number(balance) < Number(this.willPay)){
                 warningMsg("账户余额不足！")
                 return;
             }
            //1.创建未支付订单
            var params = {
                willPay: this.willPay,
                count: this.packetCount,
                imei: this.$route.params.imei,
                packetId: this.selectPacket.packetId,
                attachedId: this.selectPacket.detailId,
                url: window.location.href.split('#')[0],
                supportEffect: 0,
                timeUnit: 0,
                payType: payType,
                type: 0,
                packetType: 0,
            }
            var currentObj = this; //解决this指向问题
            this.axios.post(this.baseUrl + "/order/createOrder",params,{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}})
            .then((res)=>{
                if(res.status == 200){
                    var datas = res.data.data
                    if(res.data.code == 1 && datas != null){
                         if(datas.status == 1){  //预定义支付接口封装成功
                            this.$modal.confirm({
                        centered: true,
                        icon: "null",
                        width: "80%",
                        title: "确认支付提示",
                        content: "请确认购买流量套餐"+currentObj.selectPacket.name + (currentObj.packetCount==1?"":(" * " + currentObj.packetCount)) ,
                        okText: "确定",
                        okType: "primary",
                        cancelText: "取消",
                        onOk() {
                            //对数据进行处理，判断是微信支付还是余额支付
                            if(datas.payType == 0){
                                datas.page = "out"
                                currentObj.payOrder(datas, params.imei)
                            }else if(datas.surePayVO.payType == 1){
                                datas.surePayVO.page = "out"
                                //发起一个支付请求
                                currentObj.$wx.chooseWXPay({
                                    timestamp: datas.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                    nonceStr: datas.nonceStr,  // 支付签名随机串，不长于 32 位
                                    package: datas.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                                    signType: datas.signType,  // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                    paySign: datas.paySign,    // 支付签名
                                    success: function (res) {
                                        // 支付成功后的回调函数
                                        if(!res.err_msg){
                                            currentObj.payOrder(datas.surePayVO, params.imei)
                                        }else{
                                            errorMsg("支付失败")
                                        }
                                    }
                                });
                            }else{
                                errorMsg("支付异常，请联系管理员")
                            }
                        },
                        onCancel() {
                            warningMsg("支付已取消！")
                        },
                        });
                        }else{
                        warningMsg("购买失败!");
                        }
                    }else if(res.data.code == 600000){
                        warningMsg(res.data.message)
                        this.$router.push({name: 'login'})
                    }else{
                        warningMsg("购买失败!");
                    }
                }else{
                    warningMsg("购买失败!")
                }
            }).catch((err)=>{
               errorMsg("支付异常")
            })
        },

        payOrder(params, imei){
           this.axios.post(this.baseUrl + "/order/surePay",params,{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}
           ).then((res)=>{
                if(res.status == 200){
                    if(res.data.code == 1 && res.data.data != null){
                        this.$router.push({ name: "flowQuery",
                        params: {
                            imei: imei,
                        } });
                    }else{
                        warningMsg(res.data.message);
                    }
                }else{
                    errorMsg("套餐购买失败!");
                }
            }).catch((err)=>{
                errorMsg("套餐购买失败，请稍后重试！");
            })
        }
    },
}
</script>

<style lang="scss" scoped>

    p{
        margin-top: 1rem;
        font-weight: bolder;
        font-size: 1.1rem;
        color: #333;
        margin-bottom: 0.3rem;
    }
    .packets_div{
        margin: 0 auto;
        text-align: left;
    }
    .packet_div{
        display: inline-block;
        padding: 0.5rem 0.1rem 0.4rem 0.1rem;
        border-radius: 5px;
        margin-right: 0.5rem;
        margin-top: 0.6rem;
        text-align: center;
    }
    .select_div{
        border: 1px solid #398AFF;
        color: #398AFF;
    }
    .unselect_div{
        border: 1px solid rgba(0,0,0,0.06);
        color: #333;
    }
    .name_span{
        display: inline-block;
        font-size: 0.9rem;
        font-weight: 600;
        overflow: hidden;
        width: 6rem;
        margin-top: 0.5rem;
    }
    .price_span{
        display: inline-block;
        color: #999;
        font-size: 0.8rem;
        overflow: hidden;
        width: 5rem;
        position: relative;
        top: -0.2rem;
    }

    .order_detail_p{
        margin-top: 1.2rem;
        margin-bottom: 0.6rem;
    }
    .detail_line{
        margin-top: 0.7rem;
    }
    .detail_line_first{
        margin-top: 0;
    }
    .detail_name{
        color: #666;
        font-size: 0.9rem;
        font-weight: normal;
    }
    .detail_op{
        float: right;
        font-size: 1rem;
        font-weight: bold;
    }
    .detail_count{
        padding: 0 0.4rem;
    }
    .detail_op img{
        width: 1.4rem;
    }

    .cover_p{
        margin-top: 1.2rem;
        margin-bottom: 0.6rem;
    }
    .cover_div{
        margin-top: 0.5rem;
        font-size: 0.8rem;
        font-weight: 600;
    }
    .continent_span{
        color: #2981FF;
    }
    .countries_span{
        color: #666;
    }
    .contain{
        padding-bottom: 0.1rem;
    }
    .certi_div{
        width: 100%;
        text-align: center;
        background-image: url("../asserts/common/standard_box.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 1.5rem;
        font-size: 0.8rem;
        color: #666666;
        margin-bottom: 5rem;
    }
    .certi_ins_img{
        width: 11rem;
        margin-top: 1rem;
        margin-bottom: 0.3rem;
    }
    .certi_info{
        padding-bottom: 1rem;
    }
    ul{
        width: 90%;
        display: inline-block;
        text-align: left;
        text-align: left;
        margin: 0;
        padding-left: 1rem;
    }
    li{
        font-weight: normal;
        margin-top: 0.3rem;
        color: #666;
    }

    .pay_div{
        margin-top: 2rem;
        border-top: 1px solid rgba(0,0,0,0.04);
        padding: 0 0.8rem;
        position: fixed;
        bottom: 0px;
        width: 100%;
        background-color: #fff;
    }
    .pay_span{
        display: inline-block;
        font-size: 0.9rem;
        color: #333;
        padding: 1rem 0.5rem;
        width: 62%;
    }
    .pay_money{
        font-size: 1.2rem;
        color: #FF573A;
        font-weight: bold;
    }
    .pay_a{
        position: relative;
        float: right;
        margin-top: 0.7rem;
    }
    .go_pay_btn{
        width: 7rem;
        margin-right: 0.4rem;
    }
</style>