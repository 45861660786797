<template>
  <div>
    <div style="text-align: center; padding-top: 5rem">
      <img src="/asserts/common/Logo.png" style="width: 7rem" />
    </div>
    <div style="padding-top: 5rem">
      <div class="signal_input">
        <img class="small_img" src="/asserts/common/phone.png" />
        <input
          class="input"
          type="text"
          placeholder="请输入手机号"
          v-model="loginForm.phone"
        />
      </div>
      <div class="signal_input">
        <img class="small_img" src="/asserts/common/lock.png" />
        <input
          class="code_in input"
          type="text"
          maxlength="6"
          placeholder="请输入验证码"
          v-model="loginForm.verifycode"
        />
        <a class="code" @click.prevent="sendVerifyCode">{{ verify_msg }}</a>
      </div>
    </div>
    <div class="policy"><input type="checkbox"  @change="isChecked()" name="policyInfo" class="policyInfo">我已阅读并同意<a class="policy_a" href="https://www.roam.link/ys.html">《小漫物联隐私协议》</a></div>
    <div class="login_div" style="text-align: center; padding-top: 2.3rem">
      <a class="login" @click.prevent="login()">登录</a>
    </div>
  </div>
</template>

<script>
import {checkPhone, checkVerifyCode, isNull} from '../components/VerifyParamUtil';
import {successMsg, warningMsg, errorMsg}  from '../components/prompt-message/js/TipMessage.js';
export default {
  data() {
    return {
      loginForm: {
        phone: "",
        verifycode: "",
      },
      verify_msg: "获取验证码",
      hasSend: false,
      checked: false,
    };
  },
  methods: {
    sendVerifyCode() {
      //1. 发送验证码, 并接收服务端返回, 设置定时任务, 将verify_msg内容进行更新
      let phone = this.loginForm.phone
      if(isNull(phone)){
        warningMsg("请输入手机号！");
        return;
      }
      phone = phone.trim()
      if(!checkPhone(phone)){//2. 校验手机号格式是否正确
        warningMsg("手机号码格式不正确！");
        return;
      }
      if (this.hasSend) return;
      this.hasSend = true;
      this.axios.post(this.baseUrl + "/user/code/sendVerifyCode?phone=" + phone
        ).then((result) => {
          if (result.status == 200){
              if (result.data.code == 1) {
                successMsg(result.data.message)
                // this.loginForm.verifycode = result.data.data; //回显验证码
                this.changeVerifyMsg();
              } else {
                warningMsg(result.data.message);
                this.hasSend = false;
              }
          }else{
            this.hasSend = false;
            errorMsg("发送短信失败，请稍后重试！")
          }
        }).catch((err) => {
          errorMsg("发送失败，请联系客服人员！")
          this.hasSend = false;
        });
    },
    isChecked(){
      this.checked = event.target.checked;
    },
    login() {
      let phone = this.loginForm.phone
      let verifycode = this.loginForm.verifycode
      if(isNull(phone)){
        warningMsg("请输入手机号！");
        return;
      }
      if(isNull(verifycode)){
        warningMsg("请输入验证码！");
        return;
      }
      if(!this.checked){
         warningMsg("请勾选小漫物联隐私政策！");
         return;
      }
      phone = phone.trim()
      verifycode = verifycode.trim()
      if(!checkPhone(phone)){// 校验手机号格式是否正确
        warningMsg("手机号码格式不正确！");
        return;
      }
      if(!checkVerifyCode(verifycode)){
        warningMsg("验证码有误！");
        return;
      }
      this.axios.post(this.baseUrl + "/user/code/login?phone=" + phone + "&verifyCode=" + verifycode,"",
      {headers: {'openId': localStorage.getItem('openId')}}
        ).then((res) => {
          //登录成功跳转到 我的设备页面
          if (res.status == 200){
            if(res.data.code == 1 && res.data.data != null) {
              localStorage.setItem("token", res.data.data.token);
              this.$router.push({ name: "myDevice" });
            }else{
              warningMsg(res.data.message);
            }
          }else{
            errorMsg("登录失败!");
          }
        }).catch((err) => {
          errorMsg("登录失败!")
        });
    },

    changeVerifyMsg() {
      // var i = 600;
      var i = 60; // 方便测试
      var timeInterval = setInterval(() => {
        if (i > 0) {
          this.verify_msg = i-- + "s";
        } else {
          this.verify_msg = "获取验证码";
          this.hasSend = false;
          clearInterval(timeInterval);
        }
      }, 1000);
    },
  },
};
</script>

<style  scoped>
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999999;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999999;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999999;
}
.input {
  border: 0px;
  background-color: #f5f5f5;
  margin-left: 1.8rem;
  width: 60%;
  font-size: 1rem;
  font-weight: bolder;
  outline: none;
}
.code_in {
  width: 40%;
}
.signal_input {
  width: 90%;
  margin: 0 auto;
  padding: 0.8rem 1rem;
  background-color: #f5f5f5;
  border-radius: 3rem;
  margin-top: 1rem;
  position: relative;
}
.small_img {
  width: 0.8rem;
  margin: 0rem 0.3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.code {
  color: #ff573a;
  text-decoration: none;
  float: right;
  margin-right: 0.3rem;
  font-weight: bolder;
}
.policy{
  vertical-align:middle;
  margin-top: 0.8rem;
  font-size: 0.9rem;
  color: #999999;
}
.policyInfo{
  vertical-align:middle;
  border-color: #999999;
  margin-left: 2.4rem;
  margin-right: 0.3rem;
}
.policy_a{
  text-decoration: none;
}
.login {
  width: 90%;
  margin: 0 auto;
  padding: 0.8rem 1rem;
  border-radius: 3rem;
  /* margin-top: 1rem; */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 1rem;
  background-image: url("../asserts/common/blue_btn_long.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 3.5rem;
  line-height: 1.7rem;
}
</style>