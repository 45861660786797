import {message} from 'ant-design-vue'
message.config({
  top: `280px`,
  duration: 1.5,
  maxCount: 1,
});
let successMsg = ((msg) => {
  message.success(msg)
})

//警告
let warningMsg = ((msg) => {
  message.warning(msg)
})

//错误
let errorMsg = ((msg) => {
  message.error(msg)
})

export { successMsg, warningMsg, errorMsg }