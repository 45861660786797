/**
 * 对字符串进行格式化: 隔几位添加一个空格
 * @param {*} numStr 要格式化的字符串
 * @param {*} num 每隔多少位进行添加
 * @returns 
 */
let str_add_space_format = ((numStr, num) => {
    var reg = new RegExp('(.{' + num + '})', 'g')
    return numStr.replace(/\s/g, '').replace(reg, "$1 ")
})

let number_format = ((numStr, num) => {
    return numStr.toFixed(num)
})

export { str_add_space_format, number_format }