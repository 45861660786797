<template>
  <div style="width: 92%; margin: 0 auto">
    <div class="model_div">
      <p>TSID/IMEI号</p>
      <div class="input_div">
        <input
          type="text"
          placeholder="请录入设备的TSID/IMEI号"
          v-model="imei"
        />
        <a>
          <img
            class="scan_img"
            src="/asserts/common/scan.png"
            @click.prevent="base_scan(0)"
          />
        </a>
      </div>
      <span class="show_msg">可扫描设备二维码(打开设备前盖可见)录入</span>
    </div>
    <div class="model_div">
      <p>ICCID号</p>
      <div class="input_div">
        <input
          ref="copyIccid"
          type="text"
          placeholder="请录入流量卡的ICCID号"
          v-model="iccid"
        />
        <a>
          <img
            class="scan_img"
            src="/asserts/common/scan.png"
            @click.prevent="base_scan(1)"
          />
        </a>
      </div>
      <span class="show_msg">可扫描流量卡上的条形码录入</span>
    </div>
    <div class="bind_div">
      <a class="bind_devive_a" @click.prevent="bindDevice">开始实名认证</a>
      <div class="alert_div">
        如果已实名,点击<a class="to_my_device" @click.prevent="toMyDevice"
          >跳转至我的设备</a
        >
      </div>
    </div>
    <div class="certi_div">
      <img class="certi_ins_img" src="/asserts/common/certi_ins.png" />
      <div class="certi_div_info">
        <p class="first_p">
          根据工信部《电话用户真实身份登记规定》，您当前使用的终端和流量卡必须实名认证才可以正常使用。
        </p>
        <ul>
          <li>每个身份证和手机号最多实名认证5次；</li>
          <li>任何机构或个人不允许代替他人进行实名认证；</li>
          <li>所有用户实名认证信息需要保证真实有效；</li>
          <li>
            一机一卡，用户擅自将流量卡插入其他终端，流量卡将被锁定，不能正常使用。
          </li>
        </ul>
        <p class="last_p">若您在实名认证中遇到任何问题，请及时与客服联系!</p>
      </div>
    </div>
  </div>
</template>

<script>
import {isNull} from "../components/VerifyParamUtil"
import { errorMsg, successMsg, warningMsg} from '../components/prompt-message/js/TipMessage';
export default {
  data() {
    return {
      imei: "",
      iccid: "",
    };
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      //微信公众号相关功能调用时需要初始化配置
        if(localStorage.getItem("flag")==0){
        this.axios.post(this.baseUrl + "/device/scan?url="+window.location.href.split("#")[0],"",{headers: { token: localStorage.getItem("token"),
        openId: localStorage.getItem("openId")}}).then((result)=>{
          if(result.status == 200){
              let res = result.data;
              if(res.code == 1 && res.data != null){
                this.$wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: res.data.appId, // 必填，公众号的唯一标识
                      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                      signature: res.data.signature,// 必填，签名，见附录1
                      jsApiList: ["scanQRCode", ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                  })
                  localStorage.setItem("flag", 1)
                  this.$wx.error(err=>{
                   localStorage.setItem("flag", 0)
                })
          }
            }
        }).catch((err)=>{
        errorMsg("获取设备信息失败，请联系客服人员！")
        });
      }
      this.imei = this.$route.params.imei;
      this.iccid = this.$route.params.iccid;
      if(isNull(localStorage.getItem("token")) && isNull(localStorage.getItem("openId"))){
          this.$router.push({name: 'login'})
      }
    },
    base_scan(flag) {
      //微信摄像头调用
       var curThis = this
       this.$wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr.split(",")[1]; // 当needResult 为 1 时，扫码返回的结果
        
          if(flag == 0){
             var imei2 = res.resultStr.substr(res.resultStr.lastIndexOf(":")+1)
             // imei扫描结果校验
            if(isNull(result) && isNull(imei2)){
                warningMsg("请重新扫描设备")
                return;
            }
            result = isNull(result)?imei2:result
            curThis.imei = result
          }else if(flag == 1){ // 扫iccid
            if(isNull(result)){
              warningMsg("请重新扫描设备")
              return;
            }
            curThis.iccid = result
          }
        },
       error: function (res) {
           warningMsg("扫码失败！")
        },
      });
    },

    copyIccid(){  // 复制input内容到剪切板
        const inp = this.$refs.copyIccid
        inp.select()
        document.execCommand('copy');//执行copy操作
        warningMsg("iccid已复制到剪切板")
    },

    bindDevice() {
      //绑定设备
      var imei = this.imei;
      var iccid = this.iccid;
      //校验数据是否正确
      if(isNull(imei)){
        warningMsg("设备不能为空")
        return;
      }
       if(isNull(iccid)){
        warningMsg("卡不能为空")
        return;
      }
      imei = imei.trim()
      iccid = iccid.trim()
      this.axios.post(this.baseUrl + "/device/bindDeviceAndCard?imei=" + imei +"&iccid=" + iccid,"",{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}).then((res) => {
        if (res.status == 200) {
            if(res.data.code == 400301){
              successMsg(res.data.message)
              var data = res.data.data;
              if(data.belong == 3 || data.belong == 1){
                window.location.href = data.link +'&123=123&random='+Math.random(); 
                localStorage.setItem("flag", 0)
              }else if(data.belong == 2 ){
                this.$router.push({name: 'toBindCard'})
                this.copyIccid()
                localStorage.setItem("flag", 0)
              }
            }else if(res.data.code == 1){
              successMsg(res.data.message)
              this.$router.push({name: 'myDevice'})
            }else if(res.data.code == 5){
              warningMsg(res.data.message)
              this.$router.push({name: 'login'})
            }else{
              warningMsg(res.data.message)
            }
        }else{
          errorMsg("请求失败");
        }
      }).catch((err) => {
        errorMsg("请求失败, 请稍后重试");
      });
    },

    toMyDevice() {
      this.$router.push({ name: "myDevice" });
    },

  },
};
</script>

<style scoped>
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999999;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999999;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999999;
}
.model_div {
  margin-top: 1.8rem;
}
p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.7rem;
}
.input_div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 0.3rem;
}
input {
  border: none;
  font-size: 0.9rem;
  font-weight: normal;
  width: 80%;
  outline: none;
}
.scan_img {
  float: right;
  width: 1.3rem;
  margin-right: 0.3rem;
}
.show_msg {
  color: #2981ff;
  font-size: 0.7rem;
}

.bind_div {
  margin-top: 2rem;
}
.bind_devive_a {
  display: inline-block;
  width: 100%;
  padding: 0.9rem 0;
  text-align: center;
  background-image: url("../asserts/common/blue_btn_long.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
.alert_div {
  margin-top: 0.3rem;
  text-align: center;
  font-size: 0.85rem;
  color: #999;
}
.to_my_device {
  text-decoration: none;
  color: #2981ff;
}

.certi_div {
  width: 100%;
  text-align: center;
  background-image: url("../asserts/common/standard_box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  color: #666666;
}
.certi_ins_img {
  width: 11rem;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}
.certi_div_info {
  width: 90%;
  display: inline-block;
  text-align: left;
}
.first_p {
  font-size: 0.8rem;
  color: #666666;
  font-weight: normal;
  margin-top: 0.4rem;
}
ul {
  text-align: left;
  margin: 0;
  padding-left: 1rem;
}
li {
  font-weight: normal;
  margin-top: 0.3rem;
  color: #666;
}
.last_p {
  font-size: 0.8rem;
  color: #227af4;
  font-weight: normal;
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;
}
</style>