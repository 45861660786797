<template>
    <div>
        <img class="zt_img" src="/asserts/common/zt_unicom.jpg" >
    </div>
</template>

<script>
export default {
    data:{

    }
}
</script>

<style scoped>
.zt_img{
    width: 100%;
    height: 100%;
}
</style>