<template>
  <div class="bk_div" >
    <div class="swiper_div">
      <swiper-model
        :alert-msg="alertMsg"
        :use-final-slid="true"
        :paramImei="paramImei"
        :paramIccid="paramIccid"
        :flag="1"
        @get-countrys="getCountrys"
        @init-data="initData"
      ></swiper-model>
    </div>
    <div style="width: 92%; margin: 0 auto">
      <div class="packet_model" v-if="this.usingPacket.length>0">
        <p class="model_p">使用中</p>
        <div class="packet_model_div">
          <div
            :class="[
              usingPacket[usingPacket.length - 1].orderId == packet.orderId
                ? 'packet_model_singal_last'
                : 'packet_model_singal',
            ]"
            v-for="packet in usingPacket"
            :key="packet.orderId"
          >
            <div class="line_one">
              <span class="line_one_countryName">{{ packet.countryName }}</span>
              <span class="line_one_orderTime">{{ packet.orderTime }}</span>
              <span class="line_one_opt" @click.prevent="refund_order(packet.orderId, packet.packetName)">退订</span>
            </div>
            <div class="line_two">
              <a-progress :percent="(packet.allFlow-packet.releaseFlow)/packet.allFlow*100" :show-info="false"
                     :stroke-color="{'0%': '#0EC8F6','100%': '#2981FF'}" status="active" />
            </div>
            <div class="line_three">
              <span class="line_three_blue1">{{ packet.packetName }}</span>
              <div class="line_three_right">
                <span
                  >剩余<span class="line_three_blue"
                    >{{ packet.releaseFlow | num_format(2) }}GB</span
                  ></span
                >
                <span class="line_three_all"
                  >共<span class="line_three_blue"
                    >{{ packet.allFlow }}GB</span
                  ></span
                >
              </div>
            </div>
            <div class="line_four">
              <span class="line_three_expire" v-show="packet.endTime"
                >失效时间:{{ packet.endTime }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="packet_model packet_model_final" v-if="this.endPacket.length>0">
        <p class="model_p">已结束</p>
        <div class="packet_model_div">
            <div
              :class="[
                endPacket[endPacket.length - 1].orderId == packet.orderId
                  ? 'packet_model_singal_last'
                  : 'packet_model_singal',
              ]"
              v-for="packet in endPacket"
              :key="packet.orderId"
            >
              <div class="line_one">
                <span class="line_one_countryName">{{ packet.countryName }}</span>
                <span class="line_one_orderTime">{{ packet.orderTime }}</span>
                <span class="line_one_final" v-if="packet.packetStatus == 2">已退订</span>
              </div>
              <div class="line_two">
                <a-progress :percent="(packet.allFlow-packet.releaseFlow)/packet.allFlow*100" :show-info="false" 
                              :stroke-color="'#D8D8D8'" />
              </div>
              <div class="line_three">
                <span class="line_three_blue1">{{ packet.packetName }}</span>
                <div class="line_three_right">
                  <span
                    >剩余<span class="line_three_blue"
                      >{{ packet.releaseFlow }}GB</span
                    ></span
                  >
                  <span class="line_three_all"
                    >共<span class="line_three_blue"
                      >{{ packet.allFlow }}GB</span
                    ></span
                  >
                </div>
              </div>
              <div class="line_four">
                <span class="line_three_expire" v-show="packet.endTime"
                  >失效时间:{{ packet.endTime }}</span
                >
              </div>
            </div>
        </div>
        <div class="load-moudle" v-if="this.endPacket.length >= 5">
          <footer class="load-more">
            <slot name="load-more">
              <div class="more-tip" v-if="pullUpState==1">
                <span class="more-text">{{pullUpInfo.moreText}}</span>
              </div>
              <div class="loading-tip" v-if="pullUpState==2">
                <span class="loading-icon"></span>
                <span class="loading-text">{{pullUpInfo.loadingText}}</span>
              </div>
              <div class="no-more-tip" v-if="pullUpState==3">
                <span class="connecting-line"></span>
                <span class="no-more-text">{{pullUpInfo.noMoreText}}</span>
                <span class="connecting-line"></span>
              </div>
            </slot>
          </footer>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <!-- <a class="packet_buy_a" @click.prevent="to_inner_packet_buy"
        >国内套餐购买</a
      > -->
      <a class="packet_buy_a" @click.prevent="to_outer_packet_buy"
        >套餐购买</a
      >
    </div>
  </div>
</template>

<script>

import {Progress} from 'ant-design-vue';
import {isNull} from "../components/VerifyParamUtil";
import { errorMsg, successMsg, warningMsg } from '../components/prompt-message/js/TipMessage';
import SwiperModel from '../components/SwiperModel.vue'
export default {
  components: { SwiperModel,
                AProgress: Progress,
},
  data() {
    return {
      pullUpState: 1,
      pullUpInfo: {
        moreText: "上拉加载更多",
        loadingText: "加载中",
        noMoreText: "我是有底线的",
      },
      timer: null, //定时器
      selectImei: '',
      selectIccid: '',
      paramImei: '',
      paramIccid: '',
      alertMsg: "流量查询: 可扫描设备二维码(打开设备前盖可见)进行流量查询",
      endPacket: [],
      //   {
      //     orderId: 8,
      //     orderTime: "2021/03/02 23:07",
      //     countryName: "中国",
      //     allFlow: 1000,
      //     releaseFlow: 110.91,
      //     startTime: "2021/03/01 00:00",
      //     endTime: "2021/03/31 23:59",
      //     packetName: "100GB/月",
      //   },
      // ],
      usingPacket: [],
      //   {
      //     orderId: 1,
      //     orderTime: "2021/03/02 22:00",
      //     countryName: "中国",
      //     allFlow: 3000.92,
      //     releaseFlow: 1180.91,
      //     startTime: "2021/03/01 00:00",
      //     endTime: "2021/03/31 23:59",
      //     packetName: "100GB/月",
      //   },
      // ],
      devices: [],
    };
  },
  created() {
    this.init();
  },
  mounted(){
     window.addEventListener("scroll", this.PullUpReflash,true)
  },
  destroyed(){
     window.removeEventListener("scroll", this.PullUpReflash)
  },
  methods: {
    init() {
      //微信公众号相关功能调用时需要初始化配置
        if(localStorage.getItem("flag")==0){
        this.axios.post(this.baseUrl + "/device/scan?url="+window.location.href.split("#")[0],"",{headers: { token: localStorage.getItem("token"),
        openId: localStorage.getItem("openId")}}).then((result)=>{
          if(result.status == 200){
              let res = result.data;
              if(res.code == 1 && res.data != null){
                this.$wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: res.data.appId, // 必填，公众号的唯一标识
                      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                      signature: res.data.signature,// 必填，签名，见附录1
                      jsApiList: ["scanQRCode",] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                  })
                  localStorage.setItem("flag", 1)
                this.$wx.error(err=>{
                   localStorage.setItem("flag", 0)
                })
              }
            }
        }).catch((err)=>{
        // errorMsg("获取设备信息失败，请联系客服人员！")
        });
      }
      this.paramImei = this.$route.params.imei
      this.paramIccid = this.$route.params.iccid
      if(isNull(this.paramImei)){
        this.paramImei = ""
      }
      if(isNull(this.paramIccid )){
        this.paramIccid = ""
      }
    },
    refund_order(orderId, packetName){
      if(isNull(orderId)){
         warningMsg("退订失败")
         return;
      }
      let currentThis = this
      this.$modal.confirm({
          centered: true,
          icon: "null",
          width: '80%',
          title: '确定退订流量吗?',
          content: '确定退订流量包: '+ packetName,
          okText: '确定',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
                currentThis.axios.post(currentThis.baseUrl + "/order/rebund/flow?orderId="+orderId, "", {headers: { token: localStorage.getItem("token"),
                    openId: localStorage.getItem("openId")}}).then((res)=>{
                  if(res.status == 200){
                    if(res.data.code == 1){
                        successMsg(res.data.message)
                        currentThis.getCountrys(currentThis.selectImei, currentThis.selectIccid)
                    }else if(res.data.code == 0){
                        warningMsg(res.data.message)
                    }else{
                      warningMsg("退订失败！")
                    }
                  }else{
                      errorMsg("退订失败！")
                  }
                }).catch((err)=>{
                  errorMsg("退订失败，请稍后重试！") 
                })
          },
        onCancel() {
           warningMsg("已取消！")
        }
      })
    },

    initData(datas){
      if(datas != null){
        if(datas.devices != null && datas.devices.length > 0){
            if(this.selectImei == '862716123456789'){
              this.selectImei = '862716123456789'
              this.devices = []
            }else{
              this.devices = datas.devices
              this.devices.filter(item => {
                if (item.selected == 1) {
                  this.selectImei = item.imei
                  this.selectIccid = (item.iccid == null?"":item.iccid)
                }
              })
            }
            this.endPacket = datas.endPacket==null?[]:datas.endPacket;
            this.usingPacket = datas.usingPacket==null?[]:datas.usingPacket;
        }
      }
    },
    // 查流量，getCountrys命名是因为和查国家共用了同一个滑动
    getCountrys(imei, iccid) {
       this.endPacket = []
       this.usingPacket = []
       
    
      clearTimeout(this.timer);
      if(imei == 111){
        return;
       } 
      this.selectImei = imei
      this.selectIccid = iccid
      this.pullUpState = 1
      this.timer = setTimeout(()=>{ 
          this.axios.post(this.baseUrl + "/device/queryFlow/changeDevice?imei="+imei+"&iccid="+iccid, "", {headers: { token: localStorage.getItem("token"),
              openId: localStorage.getItem("openId")}}).then((res)=>{
              if(res.status == 200){
                  if(res.data.code == 1){
                    if(res.data.data != null){
                        this.endPacket = res.data.data.endPacket==null?[]:res.data.data.endPacket;
                        this.usingPacket = res.data.data.usingPacket==null?[]:res.data.data.usingPacket;
                    }
                  }else if(res.data.code == 0){
                    warningMsg(res.data.message)
                  }
              }else{
                errorMsg("获取流量失败！")
              }
          }).catch((err)=>{
            errorMsg("获取流量失败，请稍后重试！")
          })
      }, 500)
     
    },
    PullUpReflash(){
      let innerHeight = document.documentElement.scrollHeight;
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight  || document.body.scrollHeight;
      if (innerHeight <= scrollTop + clientHeight && this.endPacket.length >= 5 ) {
        if ( this.pullUpState != 3 && !this.isLoading) { //还有数据，并且未处于加载状态
          this.change_load_state(2, true)
          setTimeout(()=>{
              this.axios.post(this.baseUrl + "/device/pullUp/refresh?imei="+this.selectImei+"&&count="+this.endPacket.length, "", {headers: { token: localStorage.getItem("token"),
              openId: localStorage.getItem("openId")}}).then((res)=>{
              if(res.status == 200){
                if(res.data.code == 1 && res.data.data.length > 0){
                    this.endPacket = this.endPacket.concat(res.data.data)
                    if(res.data.data.length < 5){
                        this.change_load_state(3, false)
                    }else{
                      this.change_load_state(1, false)
                    }
                }else if(res.data.code == 1 && res.data.data.length == 0){
                  this.change_load_state(3, false)
                }
              }else{
                  errorMsg("加载失败！")
                  this.change_load_state(1, false)
              }
            }).catch((err)=>{
              errorMsg("加载失败，请稍后重试！") 
              this.change_load_state(1, false)
            })
          }, 1000)
        }
      }
    },
    change_load_state (status, loading) {
        this.pullUpState = status
        this.isLoading = loading
    },

    to_inner_packet_buy(){
        let count = 0
        if(this.selectImei == '862716123456789'){
             this.$router.push({
                name: "innerPacketBuy",
              })
              count = 1
        }
        if(!isNull(this.selectImei) && this.selectImei != '862716123456789' && isNull(this.selectIccid)){
          warningMsg("请先实名！")
          count = 1
          return;
        }
        
        for (let i = 0; i < this.devices.length; i++) {
          const device = this.devices[i];
          if(device.imei == this.selectImei && device.iccid != null
            && device.iccid == this.selectIccid && device.status == 0){
              this.$router.push({
                name: "innerPacketBuy",
              })
              count = 1
              break;
          }
        }
        if(count == 0){
             warningMsg("请实名认证！")
             return;
        }
    },
    to_outer_packet_buy(){
      window.location.href='/order/outer'
      // if(this.selectImei == 111){
      //     this.$router.push({
      //       name: "outerPacketBuy",
      //     })
      //   return
      // }
      // this.$router.push({
      //     name: "outerPacketBuy",
      //     params:{
      //       imei: this.selectImei,
      //       iccid: this.selectIccid
      //     }
      // })
    }
  },
};
</script>

<style scoped>
.bk_div {
  background-color: #f5f5f5;
  position: relative;
  height: 100%;
  min-height: 100vh;   /*最小高度整个屏幕*/
  width: 100%;
}
.swiper_div {
  padding-top: 1rem;
  margin-left: 0.8rem;
}
.packet_model_div {
  background-color: #fff;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
}
.model_p {
  margin: 1.4rem 0 0.4rem 0.2rem;
  background-image: url("../asserts/common/left.png");
  background-repeat: no-repeat;
  background-size: 0.4rem 1rem;
  background-position: 0.2rem;
  color: #333;
  font-size: 1.1rem;
  font-weight: bolder;
  padding-left: 1.2rem;
}
.packet_model_singal {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  padding: 0.8rem 0;
}
.packet_model_singal_last {
  padding: 0.8rem 0;
  margin-bottom: 0.3rem;
}
.line_one {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}
.line_one_orderTime {
  margin-left: 1rem;
}
.line_one_opt {
  float: right;
  border: 1px solid #ddd;
  border-radius: 1rem;
  color: #333;
  font-size: 0.7rem;
  font-weight: normal;
  padding: 0.05rem 0.6rem;
}
.line_one_final {
  float: right;
  border-radius: 1rem;
  color: #FF7744;
  font-size: 0.7rem;
  font-weight: normal;
  padding: 0.05rem 0.6rem;
}
.line_three {
  font-size: 0.7rem;
  color: #999;
  font-weight: bold;
}
.line_three_right {
  /* display: inline-block; */
  float: right;
}
.line_three_all {
  margin-left: 0.5rem;
}
.line_three_blue1 {
  display: inline-block;
  color: #2981ff;
  width: 32%;
}
.line_three_blue {
  display: inline-block;
  color: #2981ff;
}
.line_four {
  font-size: 0.7rem;
  color: #999;
  font-weight: bold;
  text-align: right;
  margin-top: 0.2rem;
}
.line_four_expire {
  font-weight: normal;
}

.packet_model_final {
  padding-bottom: 5rem;
}
.footer_btn {
  text-align: center;
  margin-top: 5rem;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.6rem;
  background-color: #fff;
}

.footer_btn a {
  width: 90%;
  display: inline-block;
  color: #fff;
  padding: 0.6rem 0;
  text-decoration: none;
  font-size: 1rem;
}
/* .footer_btn a:first-child {
  margin-right: 0.3rem;
} */
/* .footer_btn a:nth-child(2) {
  margin-left: 0.3rem;
} */
.flow_query_a {
  background-image: url("../asserts/common/blue_btn.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.packet_buy_a {
  background-image: url("../asserts/common/支付.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* ---------------上拉加载-------------------- */
.load-more {
  width: 100%;
  color: #b3b3b3;
  background: #f5f5f5;
  font-size: 0.6rem;
}

.more-tip,
.loading-tip,
.no-more-tip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

.load-moudle .loading-icon {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  background: url("../asserts/common/loading.gif") no-repeat;
  background-size: cover;
  margin-right: 5px;
  /* animation: rotating 2s linear infinite; */
}

/* @keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}  */

.connecting-line {
  display: inline-flex;
  width: 7rem;
  height: 1px;
  background: #ddd;
  margin-left: 12px;
  margin-right: 12px;
} 
</style>