<template>
  <div style="width: 92%; margin: 0 auto">
    <p class="device_p">设备列表</p>
    <swiper-model
      :alert-msg="alertMsg"
      :use-final-slid="true"
      :paramImei="paramImei"
      :paramIccid="paramIccid"
      :flag="2"
      @get-countrys="getCountrys"
      @init-data="initData"
    ></swiper-model>
    <div class="search_div">
      <img class="search_div_img" src="/asserts/common/search.png" alt="" />
      <input
        class="search_div_input"
        type="text"
        v-model="newSearchValue"
        placeholder="请输入国家或地区名称"
        @input = "searchSupportCountry"
        />
    </div>
    <!-- <div class='default_device' v-if="this.selectImei == '862716123456789'">
       <p class="prompt_info">
         *&nbsp;以下价格适用于{{this.defalutDevice[this.selectDefaultDevice]}},你可以点击查看
         <a class="change_default_device" @click.prevent="defaultDeviceSupportCountry(selectDefaultDevice)">
            {{this.defalutDevice[this.selectDefaultDevice==1?0:1]}}
         </a>
          的价格
        </p>  
    </div> -->
    <!-- <div class="country_div" v-show="countryShow">
      <a
        :class="[
          'singal_country',
          item.countryCode == showCountryList[showCountryList.length - 1].countryCode
            ? 'singal_country_final'
            : '',
        ]"
        v-for="item in showCountryList"
        :key="item.countryCode"
        @click.prevent="toCountryDetail(item.countryCode, item.name)"
      >
        <img class="country_img" :src="item.imageUrl" />
        <span class="country_name">{{ item.name }}</span>
        <div class="price_span">
          <span class="country_price"
            >¥{{ item.minPrice | num_format(2) }}</span
          >
          <img class="gt_img" src="/asserts/common/gt.png" />
        </div>
      </a> 
    </div> -->
    <div class="country_div" v-show="countryShow">
      <div v-for="item in continentCodeList" :key="item" v-show="continentCountList[item] > 0">
        <h2 :class="['continent_h1', continentShowList[item] ? 'continent_h1_close':'continent_h1_show',]" @click.prevent.stop="changeShowContinent(item)">{{contNameMap[item]}} 
          <span class="continent_h1_count">- {{continentCountList[item]}} -</span>
        </h2>
        <div v-show="continentShowList[item]">
          <a
            class="singal_country"
            v-for="item1 in countryMap[item]"
            :key="item1.countryCode"
            @click.prevent="toCountryDetail(item1.countryCode, item1.name)"
            v-show="item1.show"
          >
            <img class="country_img" :src="item1.imageUrl" />
            <span class="country_name">{{ item1.name }}</span>
            <div class="price_span">
              <span class="country_price"
                >¥{{ item1.minPrice | num_format(2) }}</span
              >
              <img class="gt_img" src="/asserts/common/gt.png" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorMsg, warningMsg} from '../components/prompt-message/js/TipMessage';
import SwiperModel from "../components/SwiperModel.vue";
import { isNull } from '../components/VerifyParamUtil';
export default {
  data() {
    return {
      alertMsg:
        "国际套餐购买: 可扫码设备二维码(打开设备前盖可见)进行国际套餐购买",
      selectImei: "",
      oldSearchValue: "",
      newSearchValue: "",
      countryShow: true,
      paramImei: '',
      paramIccid: '',
      // defalutDevice: ["RoamWiFi R9/R10", "RoamLink Mini11"],
      // selectDefaultDevice: "",
      // support: "",  // 默认设备支持的卡类型
      // countryLists: [
      //   // {
      //   //   countryCode: 4,
      //   //   imageUrl:
      //   //     "http://img.roamwifi.com/phoneImage/202008//4ac4a87e-4fff-4a55-8cf9-4b81d73589f5.jpg",
      //   //   name: "阿富汗",
      //   //   minPrice: 36,
      //   //   curOver: "", 当前国家所有支持套餐的套餐覆盖范围
      //   // },
      // ],
      // showCountryList: [],
      devices: [],
      continentCodeList: [],
      continentShowList: [],
      continentCountList: [],
      contNameMap: '',
      countryMap: '',
      flag: false, // 防止用户恶意点击
    };
  },
  components: { SwiperModel },
  created: function () {
    this.init();
  },
  methods: {
    init() {
      //微信公众号相关功能调用时需要初始化配置
        if(localStorage.getItem("flag")==0){
        this.axios.post(this.baseUrl + "/device/scan?url="+window.location.href.split("#")[0],"",{headers: { token: localStorage.getItem("token"),
        openId: localStorage.getItem("openId")}}).then((result)=>{
          if(result.status == 200){
              let res = result.data;
              if(res.code == 1 && res.data != null){
                this.$wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: res.data.appId, // 必填，公众号的唯一标识
                      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                      signature: res.data.signature,// 必填，签名，见附录1
                      jsApiList: ["scanQRCode",] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                  })
                  localStorage.setItem("flag", 1)
                  this.$wx.error(err=>{
                   localStorage.setItem("flag", 0)
                })
          }
            }
        }).catch((err)=>{
        // errorMsg("获取设备信息失败，请联系客服人员！")
        });
      }

      this.paramImei = this.$route.params.imei
      this.paramIccid = this.$route.params.iccid
      if(isNull(this.paramImei)){
        this.paramImei = ""
      }
      if(isNull(this.paramIccid )){
        this.paramIccid = ""
      }
    },
    changeShowContinent(continentCode){
      this.continentShowList.splice(continentCode, 1, !this.continentShowList[continentCode])
    },
    toCountryDetail(countryCode, name) {
        //跳转国家详情页面
        this.$router.push({
          name: "countryDetail",
          params: {
            countryCode: countryCode,
            countryname: name,
            imei: this.selectImei,
          },
        }); 
    },
    initData(datas){
      this.devices = datas.devices
      this.parseStorageDevice(datas)
      sessionStorage.setItem(this.selectImei, JSON.stringify(datas))
      },

    getCountrys(imei, iccid) {
      // 校验imei
      if(isNull(imei)){
        return;
      }
      this.contNameMap = ''
      this.countryMap = ''
      this.oldSearchValue = ""
      this.newSearchValue = ""
      this.selectImei = imei;
      if(imei == 111){
        this.countryShow = false
        return;
      }
      this.countryShow = false
      let deviceData = sessionStorage.getItem(this.selectImei)
      if((deviceData == null || typeof(deviceData) == "undefined") && this.selectImei != 111){
          this.axios.post(this.baseUrl + "/device/supportCountry?imei="+imei+"&iccid="+iccid, "", {headers: { token: localStorage.getItem("token"),
                openId: localStorage.getItem("openId")}}).then((res) => {
              if(res.status == 200){
                if(res.data.code == 1 && res.data.data != null){
                  this.parseStorageDevice(res.data.data)
                  sessionStorage.setItem(imei, JSON.stringify(res.data.data))
                }
              }else{
                warningMsg("获取设备支持的国家失败")
              }
            }).catch((err) => {
              errorMsg("获取设备支持的国家失败，请稍后重试！")
            });
      }else if(!(deviceData == null ||  typeof(deviceData) == "undefined") && this.selectImei != 111){
          // this.countryLists = JSON.parse(country)
          // this.showCountryList = JSON.parse(country)
          this.parseStorageDevice(JSON.parse(deviceData));
      }
      setTimeout(()=>{
        this.countryShow = true
      }, 300)
    },
    parseStorageDevice(datas){
      this.continentCodeList = datas.continentCodeList
      this.contNameMap = datas.contNameMap
      this.countryMap = datas.countryMap
      this.continentCodeList.forEach(item => {
        this.continentShowList[item] = true
      })
      this.continentCodeList.forEach(item => {
        this.continentCountList[item] = this.countryMap[item].length;
        this.countryMap[item].forEach(subItem => {
          subItem.show = true
        }) 
      })
      if(this.devices!=null && this.devices.length > 0){
        this.devices.filter(item => {
          if (item.selected == 1) {
            this.selectImei = item.imei
          }
        })
        if(this.selectImei == "862716123456789"){
          this.devices = [];
        }
      }
    },
    searchSupportCountry(){
      if(this.newSearchValue == this.oldSearchValue){
        return;
      }else{
          this.oldSearchValue = this.newSearchValue;
          for(let key in this.countryMap){
            let countryList = this.countryMap[key];
            this.continentCountList.splice(key, 1, 0)
            countryList.forEach(item => {
              let name = item.name==null?"":item.name
              name = item.curOver.concat(name)
              if(name!= null){
                if(-1 != name.search(this.newSearchValue)){
                  item.show = true;
                  this.continentCountList.splice(key, 1, this.continentCountList[key]+1)
                }else{
                  item.show = false;
                }
              }
            })
          }
          this.continentCodeList.forEach(item => {
            this.continentShowList[item] = true
          })
      }
    },
    // defaultDeviceSupportCountry(selectDefaultDevice){
    //   if(this.flag){
    //     warningMsg("请稍后再查询！")
    //     return;
    //   }
    //   this.flag = true
    //   var support = selectDefaultDevice == 1?0:1;
    //   this.support = 1 + support
    //   this.selectDefaultDevice = support
    //   if(isNull(localStorage.getItem("countryLists" + this.support))){
    //       this.axios.post(this.baseUrl + "/device/defaultDevice/supportCountry?supportCard="+this.support, "", {headers: { token: localStorage.getItem("token"),
    //         openId: localStorage.getItem("openId")}}).then((res) => {
    //       if(res.status == 200){
    //         if(res.data.code == 1 && res.data.data != null){
    //           this.countryLists = res.data.data.deviceSupportCountrys
    //           this.showCountryList = res.data.data.deviceSupportCountrys
    //           localStorage.setItem("countryLists" + this.support, JSON.stringify(this.countryLists))
    //           this.flag = false
    //         }
    //       }else{
    //         warningMsg("获取设备支持的国家失败")
    //         this.flag = false
    //       }
    //     }).catch((err) => {
    //       errorMsg("获取设备支持的国家失败，请稍后重试！")
    //       this.flag = false
    //     });
    //   }else{
    //     this.countryLists = JSON.parse(localStorage.getItem("countryLists" + this.support))
    //     this.showCountryList = this.countryLists
    //     this.flag = false
    //   }
    // }
  }
};
</script>

<style scoped>
.country_div {
  margin-top: 0.8rem;
}
.continent_h1{
  font-size: 1.4rem;
  font-weight: bolder;
  position: relative;
  top: 0.5rem;
  background-color: #dcdddd;
  padding-left: 1.5rem;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background-repeat: no-repeat;
  background-position: 94% 49%;
  background-size: 1.3rem;
}
.continent_h1_show{
  background-image: url('../asserts/common/o1.png');
}
.continent_h1_close{
  background-image: url('../asserts/common/c1.png');
}
.continent_h1_count{
  font-size: 0.9rem;
  font-weight: bolder;
}
/* .change_default_device{
  text-decoration: none;
} */
.singal_country {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  padding: 0.6rem 0.4rem 0.5rem 0;
}
.singal_country_final {
  border-bottom: 0px solid #e5e5e5;
}
.country_img {
  width: 2rem;
}
.country_name {
  margin-left: 3%;
  font-weight: 600;
}
.price_span {
  float: right;
  position: relative;
  top: 0.2rem;
}
.country_price {
  margin-right: 0.2rem;
}
.gt_img {
  width: 0.3rem;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999999;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #999999;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999999;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999999;
}
input {
  border: 0px;
  background-color: #fff;
  width: 80%;
  font-size: 0.8rem;
  font-weight: normal;
}
.device_p {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1rem;
  font-weight: normal;
  color: #333;
}

.search_div {
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 2rem;
  margin-top: 1.2rem;
  padding: 0.3rem 0;
}
.search_div_img {
  width: 1rem;
  margin-left: 1.2rem;
}
.search_div_input {
  margin-left: 0.2rem;
  outline: none;
}
/* .prompt_info{
  color: red;
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 0;
}
.default_device{
  margin-top: 0.4rem;
} */
</style>