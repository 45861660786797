<template>
  <div style="width: 92%; margin: 0 auto">
    <div class="user_div">
      <p>个人信息</p>
      <!-- <div class="line_span" style="padding-top: 0.4rem">
        <span class="name_span">姓名</span>
        <input class="base_inp" type="text" :value="user.username" disabled />
      </div> -->
      <div class="line_span" style="padding-top: 0.4rem">
        <span class="name_span">手机号</span>
        <input class="base_inp" type="text" :value="user.phone" disabled />
        <a class="change_phone" @click.prevent="changeAccount">
          <img src="/asserts/common/change.png" alt="" />
          切换账号
        </a>
      </div>
      <div class="line_span">
        <span class="name_span">余额</span>
        <input
          class="base_inp"
          type="text"
          :value="'¥' + user.balance"
          disabled
        />
      </div>
    </div>
    <div class="device_div">
      <p> 设备列表<span class="p_span" @click="scanTSID" >扫码关联设备<img class="p_scan_img"
            src="/asserts/common/scan_blue.png"/></span>
      </p>
      <div  v-if="devices != null && devices.length > 0" >
        <div v-for="item in devices" :key="item.iccid"
          :class="[ 'device_info',
            item.selected == 1 ? 'select_device' : 'unselect_device', ]"
          @click="changeDevice(item.imei, item.iccid)"
        >
          <div class="device_msg">
            <span class="ssid_span">{{ item.ssid }}</span ><br />
            <span class="simall_span">IMEI: {{ item.imei | str_format(4) }}</span><br />
            <!-- <span
              v-if="!item.iccid"
              class="simall_span go_realname"
              @click.stop="go_realname(item.imei)"
              >去实名 ></span
            >
            <span v-if="item.iccid && item.status == 0" class="simall_span"
              >ICCID: {{ item.iccid | str_format(4) }}</span
            >
            <span v-if="item.iccid && item.status == 1" class="simall_span"
             @click.stop="goRealname(item.imei, item.iccid)"
              ><span class="go_realname">实名中></span>{{ item.iccid | str_format(4) }}</span
            > -->
          </div>
          <div class="device_btn">
            <a @click.prevent.stop="removeDevice(item.ssid, item.imei)"
              >删除设备</a>
          </div>
        </div>
      </div>
      <!-- <div class="footer_btn">
        <a class="flow_query_a" @click.prevent="to_flow_query">流量查询</a>
        <a class="packet_buy_a" @click.prevent="to_outer_packet_buy"
          >国际套餐购买</a
        >
        <a class="flow_query_a" @click.prevent="to_realname">实名认证</a>
        <a class="packet_buy_a" @click.prevent="to_inner_packet_buy"
          >国内套餐购买</a>
      </div> -->
    </div>
    <!-- <div v-if="devices == null || devices.length == 0" class="bind_div">
      <a class="bind_devive_a" @click.prevent="to_bind_device">绑定设备</a>
      <div class="alert_div">
        <img class="alert_img" src="/asserts/common/alert.png" alt="" />
        <p class="alert_msg">
          在国内使用Mini-11自带卡片，需要绑定设备并实名制后，方可购买套餐正常使用。
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import { errorMsg, successMsg, warningMsg} from '../components/prompt-message/js/TipMessage';
import { isNull } from '../components/VerifyParamUtil';
export default {
  data() {
    return {
      user: {
        username: "",
        phone: "",
        balance: "",
      },
      selectImei: "",
      selectIccid: "",
      devices: [],
    };
  },
  created: function () {
    this.init_devices();
  },
  methods: {
    changeAccount() {
      //切换账号
      //2. 跳转到用户登录页面
      this.$router.push({ name: "login" });
    },
    changeDevice(imei, iccid) {
      //修改选中设备，跳转到流量查询界面
      this.$router.push({
         name: "flowQuery",
         params: {
            imei: imei,
            iccid: iccid
         }
      });
    },
    removeDevice(ssid, imei) {
      var currentObj = this; //解决this指向问题
      this.$modal.confirm({
          centered: true,
          icon: "null",
          width: '80%',
          title: '确定删除设备吗?',
          content: '移除的设备: '+ssid,
          okText: '确定',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
            currentObj.axios.post(currentObj.baseUrl + "/device/del/bindDevice?imei="+imei,"",{headers: { token: localStorage.getItem("token"),
              openId: localStorage.getItem("openId")}}).then((res)=>{
              if(res.status == 200){
                if(res.data.code == 1 && res.data.data != null){
                    successMsg(res.data.message);
                    currentObj.devices =  res.data.data;
                }else{
                    warningMsg(res.data.message);
                }
              }else{
                errorMsg("删除设备失败,请稍后重试！");
              }
            }).catch((err)=>{
                errorMsg("系统异常，请联系客服人员!");
            })
          },
        onCancel() {
           warningMsg("已取消！")
        },
      })
    },
    // 初始化我的设备
    init_devices() {
      //微信公众号相关功能调用时需要初始化配置
      if(localStorage.getItem("flag")==0){
        this.axios.post(this.baseUrl + "/device/scan?url="+window.location.href.split("#")[0],"",{headers: { token: localStorage.getItem("token"),
        openId: localStorage.getItem("openId")}}).then((result)=>{
          if(result.status == 200){
              let res = result.data;
              if(res.code == 1 && res.data != null){
                this.$wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: res.data.appId, // 必填，公众号的唯一标识
                      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                      signature: res.data.signature,// 必填，签名，见附录1
                      jsApiList: ["scanQRCode", ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                  })
                  localStorage.setItem("flag", 1)
                  this.$wx.error(err=>{
                   localStorage.setItem("flag", 0)
                })
             }
          }
        }).catch((err)=>{
        // errorMsg("获取设备信息失败，请联系客服人员！")
        });
      }
      //请求我的设备界面需要的数据
      this.axios.post(this.baseUrl + "/user/toMyDevice", "", {headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}})
      .then((res) => {
          if (res.status == 200) {
            if(res.data.code == 1 && res.data.data != null){
              var myDevice =  res.data.data;
              //数据渲染
              this.user.username = myDevice.realName;
              this.user.phone = myDevice.phoneNumber;
              this.user.balance = myDevice.balance;
              //对设备进行判断
              if(myDevice.devices != null){
                this.devices = myDevice.devices;
              }
            }else if(res.data.code == 5){
                this.$router.push({name: 'login'})
            }else{
              this.$router.push({name: 'error'})
            }
          }else{
            this.$router.push({name: 'error'})
          }
        }).catch((err) => {
          this.$router.push({name: 'error'})
        });
    },
    scanTSID() {
      var currentThis = this
      this.$wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var imei = res.resultStr.split(",")[1]; // 当needResult 为 1 时，扫码返回的结果
          var imei2 = res.resultStr.substr(res.resultStr.lastIndexOf(":")+1)
          // imei扫描结果校验
          if(isNull(imei) && isNull(imei2)){
              warningMsg("请重新扫描设备")
              return;
          }
          imei = isNull(imei)?imei2:imei
          currentThis.axios.post(currentThis.baseUrl + "/device/scan/bindDevice?imei="+imei+"&flag=0", "", {headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}).then((res) => {
            if (res.status == 200) {
              if(res.data.code == 1){
                successMsg(res.data.message)
                if(res.data.data != null){
                  currentThis.devices.push(res.data.data);
                }
               }else{
                 warningMsg(res.data.message)
               }
            }else{
               errorMsg("绑定设备失败，请稍后重试！") 
            }
        }).catch((err) => {
           errorMsg("绑定设备失败，请联系客服人员！")
        });
        },
        error: function (res) {
          errorMsg("失败")
        },
      });
    },

    to_flow_query() {
      //跳转流量查询页面
      this.$router.push({ name: "flowQuery" });
    },
    to_outer_packet_buy() {
      //跳转国家套餐购买页面
      this.$router.push({ name: "outerPacketBuy" });
    },
    to_bind_device() {
      this.$router.push({ name: "realName" });
    },
    to_inner_packet_buy() {
      let count = 0
      if(this.devices.length == 0){
        this.$router.push({
              name: "innerPacketBuy"
        })
         count = 1
      }
      if(this.devices.length > 0){
        for (let i = 0; i < this.devices.length; i++) {
          const device = this.devices[i];
          if(device.iccid != null){
            this.$router.push({
              name: "innerPacketBuy"
            })
            count = 1
            break;
          }
        }
      }
      if(count === 0 ){
        warningMsg("请先实名认证！")
      }
    },
    to_realname() {
      this.$router.push({ name: "realName" });
    },
    go_realname(curImei) {
      this.$router.push({ name: "realName", params: { imei: curImei } });
    },
    goRealname(curImei, curIccid) {
      this.$router.push({ name: "realName", params: { imei: curImei, iccid: curIccid } });  
    }
  },
};
</script>

<style scoped>
.p_span {
  font-size: 0.8rem;
  color: #2c82ff;
  float: right;
  margin-top: 0.4rem;
}
.p_scan_img {
  width: 1rem;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
}
p {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #333;
}
.user_div {
  margin-top: 1rem;
}
.line_span {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  margin-top: 0.7rem;
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
}
.name_span {
  display: inline-block;
  color: #666;
  width: 16%;
  font-size: 0.95rem;
  font-weight: normal;
}
input {
  border: 0px;
  width: 50%;
  font-weight: 600;
}
.base_inp {
  background-color: #fff;
}
.change_phone {
  float: right;
  margin-right: 0.8rem;
  color: #2c82ff;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 600;
}
a img {
  width: 0.8rem;
  padding-bottom: 0.1rem;
}

.device_div {
  margin-top: 1.5rem;
}
.device_info {
  margin-top: 0.8rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}
/* .select_device{
        background-image: url("../asserts/common/device_select.png");
    }
    .unselect_device{
        background-image: url("../asserts/common/device_unselect.png");
    } */
.device_msg {
  width: 70%;
  display: inline-block;
  margin: 1rem 0 1rem 1rem;
}
.ssid_span {
  display: inline-block;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 0.2rem;
}
.simall_span {
  color: #666666;
  font-size: 0.9rem;
  font-weight: normal;
}
.go_realname {
  color: #2c82ff;
}
.device_btn {
  float: right;
  border: 1px solid #dddddd;
  border-radius: 2rem;
  padding: 0.3rem 0.5rem;
  margin-right: 3%;
  position: relative;
  margin-top: 2rem;
  color: #666666;
  font-weight: 400;
  font-size: 0.8rem;
}
.footer_btn {
  text-align: center;
  margin-top: 5rem;
}

.footer_btn a {
  width: 48%;
  display: inline-block;
  color: #fff;
  padding: 0.6rem 0;
  text-decoration: none;
  font-size: 1rem;
}
.flow_query_a {
  background-image: url("../asserts/common/blue_btn.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.packet_buy_a {
  background-image: url("../asserts/common/red_btn.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bind_div {
  margin-top: 2rem;
}
.bind_devive_a {
  display: inline-block;
  width: 100%;
  padding: 0.9rem 0;
  text-align: center;
  background-image: url("../asserts/common/blue_btn_long.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
.alert_div {
  margin-top: 0.3rem;
  display: flex;
}
.alert_img {
  width: 0.7rem;
  height: 0.7rem;
  margin: 0.2rem 0.4rem 0 0.5rem;
}
.alert_msg {
  font-size: 0.8rem;
  color: #666;
  font-weight: normal;
  width: 98%;
}
</style>