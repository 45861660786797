import VueRouter from 'vue-router'

import Login from "../pages/Login.vue"
import MyDevice from "../pages/MyDevice.vue"
import FlowQuery from "../pages/FlowQuery.vue"
import OuterPacketBuy from '../pages/OuterPacketBuy.vue'
import RealName from '../pages/RealName.vue';
import CountryDetail from '../pages/CountryDetail.vue'
import InnerPacketBuy from '../pages/InnerPacketBuy.vue'
import ErrorPage from '../pages/ErrorPage.vue'
import ToBandCard from '../pages/ToBandCard.vue'

export default new VueRouter({
    mode: 'history', //去掉访问页面前面的 # 
    routes: [
        { path: '/', redirect: '/order/login' },
        {
            path: "/order/login",
            name: 'login',
            component: Login,
            meta: {
                title: '登录'
            }
        },
        {
            path: '/order/myDevice',
            name: 'myDevice',
            component: MyDevice,
            meta: {
                title: '我的设备'
            }
        },
        {
            path: '/order/flowQuery',
            name: 'flowQuery',
            component: FlowQuery,
            meta: {
                title: '流量查询'
            }
        },
        {
            path: '/order/outer',
            name: 'outerPacketBuy',
            component: OuterPacketBuy,
            meta: {
                title: '国际套餐购买'
            }
        },
        {
            path: '/order',
            name: 'innerPacket',
            component: InnerPacketBuy,
            redirect: "/order/inner",
            meta: {
                title: "国内套餐购买"
            },
            children: [
                {
                    path: 'inner',
                    name: 'innerPacketBuy',
                    component: InnerPacketBuy,
                    meta: {
                    title: "国内套餐购买"
                }
                }
            ]
        },
        // {
        //     // path: '/order/inner',
        //     path: '/order',
        //     name: 'innerPacketBuy',
        //     component: InnerPacketBuy,
        //     redirect: "/order/inner",
        //     children: [
        //         {
        //           path: 'inner',
        //         //   component: InnerPacketBuy,
        //           meta: {
        //             title: "国内套餐购买"
        //         }
        //         }
        //     ],
        //     // meta: {
        //     //     title: "国内套餐购买"
        //     // }
        // },
        {
            path: '/order/realName',
            name: "realName",
            component: RealName,
            meta: {
                title: '实名认证'
            }
        },
        {
            path: '/order/outerBuy',
            name: 'countryDetail',
            component: CountryDetail,
            meta: {
                title: "国际套餐购买"
            }
        },
    
        {
            path: '/order/error',
            name: "error",
            component: ErrorPage,
            meta: {
                title: '错误页面'
            }
        },
        {
            path: '/order/toZTWJUnicomAuth.htm',
            name: "toBindCard",
            component: ToBandCard,
            meta: {
                title: '实名认证绑卡'
            }
        },

        // {
        //     path: '/swiper',
        //     name: 'swiper',
        //     component: SwiperModel,
        //     meta: {
        //         title: '123'
        //     }
        // }
    ]
})