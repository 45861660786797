<template>
  <div>
    <div style="width: 92%; margin: 0 auto">
      <p class="device_p">设备列表</p>
      <swiper-model
        :flag="3"
        @get-countrys="getPackets"
        @init-data="initData"
      ></swiper-model>
      <div v-if="packets.length > 0" class="contain">
        <div class="packet_div">
          <p class="device_p device_p_pac">套餐</p>
          <div class="device_div_sub">
            <span
              :class="[
                'packet_span',
                selectedPacket.name == packet.name
                  ? 'packet_span_select'
                  : 'packet_span_unselect',
              ]"
              v-for="packet in packets"
              :key="packet.name"
              @click="changeSelectPacket(packet)"
            >
              {{ packet.name }}
            </span>
          </div>
        </div>

        <div v-show="selectedPacket.type == 0" class="packet_div" v-if="selectedPacket != '' && selectedPacket != null">
          <p class="device_p">加油包大小</p>
          <div class="device_div_sub">
            <span
              :class="[
                'packet_span',
                selectedDetail.attachedId == bag.attachedId
                  ? 'packet_span_select'
                  : 'packet_span_unselect',
              ]"
              v-for="bag in selectedPacket.details"
              :key="bag.attachedId"
              @click="changeSelectDetail(bag)"
            >
              {{ bag.size + bag.unit }}
            </span>
          </div>
        </div>

        <div v-show="selectedPacket.type == 1" class="packet_div" v-if="selectedPacket != '' && selectedPacket != null">
          <p class="device_p">流量包策略</p>
          <div class="device_div_sub">
            <span
              :class="[
                'packet_span',
                selectedDetail.attachedId == bag.attachedId
                  ? 'packet_span_select'
                  : 'packet_span_unselect',
              ]"
              v-for="bag in selectedPacket.details"
              :key="bag.attachedId"
              @click="changeSelectDetail(bag)"
            >
              {{ bag.count }}个{{ bag.dayUnit == 0 ? "天" : "月" }}
            </span>
          </div>
        </div>

        <div v-show="selectedPacket.type == 1" class="packet_div" v-if="selectedPacket != '' && selectedPacket != null">
          <p class="device_p">生效策略</p>
          <div class="device_div_sub">
            <span 
              :class="[
                'packet_span',
                selectedSupportEffect == effect
                  ? 'packet_span_select'
                  : 'packet_span_unselect',
              ]"
              v-for="effect in selectedDetail.supportEffect"
              :key="effect"
              @click="changeSelectEffect(effect)"
            >{{effect == 0?'当月生效':'次月生效'}}</span>
           </div>  
        </div>

        <div class="certi_div" v-if="selectedPacket != '' && selectedPacket != null">
          <img class="certi_ins_img" src="/asserts/common/buy_know.png" />
          <div class="certi_info" >
            <ul v-for="item in attations" :key="item">
              <li>{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="pay_div">
      <span class="pay_span"
        >合计:
        <span class="pay_money">¥{{ willPay | num_format(2) }}</span></span
      >
      <a class="pay_a" @click.prevent="toPay"
        ><img v-if="(pay || (!pay && selectedPacket.type == 0)) && selectImei != '862716123456789' " class="go_pay_btn" src="/asserts/common/goPay.png"
      /></a>
      <a class="pay_a"
        ><img v-if="(!pay && selectedPacket.type == 1) || selectImei == '862716123456789' " class="go_pay_btn" src="/asserts/common/not_pay.png"
      /></a>
    </div>
    <pay-tip
      ref="paytip"
      :will-pay="willPay"
      @create-order="createOrder"
    ></pay-tip>
  </div>
</template>

<script>
import SwiperModel from "../components/SwiperModel.vue";
import PayTip from "../components/PayTip.vue";
import { warningMsg,errorMsg,successMsg } from '../components/prompt-message/js/TipMessage';
import { isNull } from '../components/VerifyParamUtil';
export default {
  components: { SwiperModel, PayTip },
  data() {
    return {
      selectImei: "",
      selectedPacket: "",
      selectedDetail: "",
      selectedSupportEffect: 0,
      willPay: 0,
      pay: true,
      packets: [],
        // {
        //   name: "加油包",
        //   type: 0, //0: 加油包, 1-月包
        //   details: [
        //     {
        //       packetId: 1, //masterId
        //       attachedId: 1,
        //       size: 10,
        //       unit: "GB",
        //       count: 1,
        //       dayUnit: 0,
        //       supportEffect: [0], //支持的生效策略: 0-当月生效, 1-次月生效
        //       price: 12,
        //     },
        //   ],
        // },
        // {
        //   name: "100G每月",
        //   type: 1, //0: 加油包, 1-月包
        //   supportEffect: [0, 1], //支持的生效策略: 0-当月生效, 1-次月生效
        //   details: [
        //     {
        //       packetId: 1,
        //       attachedId: 1,
        //       size: 100,
        //       unit: "GB",
        //       count: 1,
        //       dayUnit: 1,
        //       supportEffect: [0, 1],
        //       price: 30,
        //     },
        //     {
        //       packetId: 1,
        //       attachedId: 2,
        //       size: 300,
        //       unit: "GB",
        //       count: 3,
        //       dayUnit: 1,
        //       supportEffect: [1],
        //       price: 60,
        //     },
        //     {
        //       packetId: 2,
        //       attachedId: 3,
        //       size: 600,
        //       unit: "GB",
        //       count: 6,
        //       dayUnit: 1,
        //       supportEffect: [0, 1],
        //       price: 90,
        //     },
        //   ],
        //   ],
        // },
      // ],

      attations: [], //订购须知
      //   "10GB告诉网络流量加油包",
      //   "加油包账期: 当月生效, 次月失效;",
      //   "加油包订购无次数限制, 订购后24小时内生效;",
      //   "云南、广西、新疆、西藏、福建地区不可使用",
      // ],
      devices: [],
    };
  },
  created: function () {
    this.init_innerPacketBuy();
  }, 
  methods: {
  
    init_innerPacketBuy() {
      //微信公众号相关功能调用时需要初始化配置
      if(localStorage.getItem("flag")==0){
        this.axios.post(this.baseUrl + "/device/scan?url="+window.location.href.split("#")[0],"",{headers: { token: localStorage.getItem("token"),
        openId: localStorage.getItem("openId")}}).then((result)=>{
          if(result.status == 200){
              let res = result.data;
              if(res.code == 1 && res.data != null){
                this.$wx.config({
                      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                      appId: res.data.appId, // 必填，公众号的唯一标识
                      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                      signature: res.data.signature,// 必填，签名，见附录1
                      jsApiList: ["scanQRCode",] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                  })
                  localStorage.setItem("flag", 1)
                  // this.wx.ready(function(){
                  // // config是异步操作，如果页面加载就要调用相关功能，则将代码写在这（确保正确调用），如果用户主动触发时调用，可以不写在这里。
                  // });
                this.$wx.error(err=>{
                   localStorage.setItem("flag", 0)
                })
                //   // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。

          }
            }
        }).catch((err)=>{
        // errorMsg("获取设备信息失败，请联系客服人员！")
        });
      }
    },
    changeSelectPacket(packet) { // 不同套餐切换会被调用
      this.selectedPacket = packet;
      this.changeSelectDetail(packet.details[0]);
    },

    changeSelectDetail(detail) { // 不同月份切换会被调用
      this.selectedDetail = detail;
      this.changeSelectEffect(detail.supportEffect[0]);
      this.attations = []
      this.packetDescriptDeal(detail.explain);
    },

    changeSelectEffect(effect) { // 不同生效策略切换会被调用
      this.selectedSupportEffect = effect;
      this.willPay = this.selectedDetail.price;
    },

    initData(res){
         if(res.data.message == "1"){ //套餐不可以购买
                  this.pay = false;
          }
          this.devices = res.data.data.devices
          if(!isNull(this.devices)){
            this.devices.filter(item => {
                if (item.selected == 1) {
                  this.selectImei = item.imei
                }
              })
            this.packets = res.data.data.packets
            if(!isNull(this.packets)){
              this.changeSelectPacket(this.packets[0])
            }else{
              warningMsg("没有可以购买的国内流量包！")
            }
          }else{
            warningMsg("请先绑定设备并实名认证！")
            this.$router.push({name: "myDevice"});
          }
        },
    getPackets(imei, iccid) {
      if(isNull(imei) || isNull(iccid)){
        warningMsg("查询流量套餐失败！")
        return;
      }
      this.selectImei = imei
      this.axios.post(this.baseUrl + "/device/inner/changeDevice?imei="+imei+"&iccid="+iccid, "",{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}})
      .then((res)=>{
          if(res.status == 200){
              if(res.data.code == 1){
                if(res.data.data != null && res.data.data.length > 0){
                  this.packets = res.data.data
                }else{
                   this.packets = []
                   this.selectedPacket = []
                   warningMsg("没有可购买的国内套餐")
                }
              }else{
                warningMsg("获取设备流量失败!");
              }
          }else{
            warningMsg("获取设备流量失败!")
          }
      }).catch((err)=>{
        warningMsg("获取设备流量失败!")
      })
    },

    toPay() {
      this.$refs.paytip.visible = true;
    },

    //响应结果中的'订购须知'处理
    packetDescriptDeal(explain){
        if(explain.length>0){
          if(explain.indexOf("<br>")>0){
            var one = explain.split("<br>");
            if(one[0].length <= 5){
                one = one.slice(1)
            }
            this.attations = one;
          }else{
            this.attations.push(explain)
          }
          
			}
    },
    createOrder(payType, balance) {
      var currentObj = this; //解决this指向问题
      if(!(payType === 0 || payType === 1)){
        warningMsg("购买失败，支付方式不正确！")
        return;
      }
      if(payType === 0 && Number(balance) < Number(this.willPay)){
        warningMsg("账户余额不足！")
        return;
      }
      var showName = ""
      if(this.selectedDetail.dayUnit == 0){
        showName = this.selectedDetail.size +  this.selectedDetail.unit + "-加油包"
      }else{
        showName = this.selectedPacket.name
      }
      //1.创建未支付订单
      var params = {
        willPay: this.willPay,
        payType: payType,
        packetId: this.selectedDetail.packetId,
        attachedId: this.selectedDetail.attachedId,
        supportEffect: this.selectedSupportEffect,
        count: 1,
        imei: this.selectImei,
        url: window.location.href.split('#')[0],
        timeUnit: this.selectedDetail.dayUnit,
        type: this.selectedPacket.type,
        packetType: 1,
        
      };
      this.axios.post(this.baseUrl + "/order/createOrder",params,{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}})
      .then((res)=>{
          if(res.status == 200){
            var datas = res.data.data
            if(res.data.code == 1 && datas != null){
              if(datas.status == 1){  //预定义支付接口封装成功
                  this.$modal.confirm({
                  centered: true,
                  icon: "null",
                  width: "80%",
                  title: "确认支付提示",
                  content: "请确认购买流量套餐"+showName,
                  okText: "确定",
                  okType: "primary",
                  cancelText: "取消",
                  onOk() {
                    //对数据进行处理，判断是微信支付还是余额支付
                    if(datas.payType == 0){
                      datas.page = "in"
                      currentObj.payOrder(datas, params)
                    }else if(datas.surePayVO.payType == 1){
                      datas.surePayVO.page = "in"
                      //发起一个支付请求
                      currentObj.$wx.chooseWXPay({
                          timestamp: datas.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                          nonceStr: datas.nonceStr, // 支付签名随机串，不长于 32 位
                          package: datas.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                          signType: datas.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                          paySign: datas.paySign, // 支付签名
                          success: function (res) {
                              // 支付成功后的回调函数
                              if(!res.err_msg){
                                currentObj.payOrder(datas.surePayVO, params)
                              }else{
                                errorMsg("支付失败")
                              }
                          }
                      });
                    }else{
                      errorMsg("支付异常，请联系管理员")
                    }
                    },
                    onCancel() {
                      warningMsg("流量套餐购买已取消！")
                    },
                  });
              }else{
                warningMsg("购买失败!");
              }
           }else if(res.data.code == 600000){
              warningMsg(res.data.message)
              this.$router.push({name: 'login'})
           }else if(res.data.code == 600100){
              warningMsg(res.data.message)
              location.reload()
           }else{
              warningMsg("购买失败!");
            }
          }else{
            warningMsg("购买失败")
          }
      }).catch((err)=>{
        errorMsg("购买失败,请稍后重试！")
      })
    },
    payOrder(datas, params) {
        // var params = {
          //orderNum,   // 订单号 willPay ,      // 总价
          // packetId:  // 流量包id
          // payType: // 支付方式 0-余额， 1-微信
          // effect:   // 请求参数--->有效策略
          // page: "in"       // 请求参数---->标记国内，国外地区
        // }
        this.axios.post(this.baseUrl + "/order/surePay",datas,{headers: { token: localStorage.getItem("token"),
            openId: localStorage.getItem("openId")}}).then((res)=>{
          if(res.status == 200){
            if(res.data.code == 1){
                successMsg("确认支付成功");
                  this.$router.push({ name: "flowQuery",
                    params: {
                      imei: params.imei,
                    }
                  });
            }else{
              warningMsg(res.data.message);
            }
          }else{
            warningMsg("套餐购买失败!");
          }
        }).catch((err)=>{
          errorMsg("套餐购买失败,请稍后重试!");
      })
    },
  },
};
</script>

<style scoped>
.packet_div {
  margin-top: 1.2rem;
}
.packet_span {
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
  margin-right: 0.6rem;
  display: inline-block;
  margin-top: 0.65rem;
}
.packet_span_select {
  color: #2981ff;
  border: 1px solid #2981ff;
}
.packet_span_unselect {
  color: #666;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.device_p {
  margin-top: 1.1rem;
  font-weight: bolder;
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.6rem;
}
.device_p_pac {
  margin-bottom: 0.5rem;
}
.device_div_sub {
  position: relative;
  top: -0.5rem;
}

.certi_div {
  width: 100%;
  text-align: center;
  background-image: url("../asserts/common/standard_box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  color: #666666;
  margin-bottom: 5rem;
}
.contain{
  padding-bottom: 0.1rem;
}
.certi_ins_img {
  width: 11rem;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}
.certi_info {
  padding-bottom: 1rem;
}
ul {
  width: 90%;
  display: inline-block;
  text-align: left;
  text-align: left;
  margin: 0;
  padding-left: 1rem;
}
li {
  font-weight: normal;
  margin-top: 0.3rem;
  color: #666;
}

.pay_div {
  margin-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  padding: 0 0.8rem;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #fff;
}
.pay_span {
  display: inline-block;
  font-size: 0.9rem;
  color: #333;
  padding: 1rem 0.5rem;
  width: 62%;
}
.pay_money {
  font-size: 1.2rem;
  color: #ff573a;
  font-weight: bold;
}
.pay_a {
  position: relative;
  float: right;
  margin-top: 0.7rem;
}
.go_pay_btn {
  width: 7rem;
  margin-right: 0.4rem;
}

</style>