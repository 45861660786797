import Vue from 'vue'

import {Drawer, Modal} from 'ant-design-vue'
import VueRouter from 'vue-router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios'
import VueAxios from 'vue-axios'


import app from "./App.vue"
import router from "./router/index.js"
import wx from './components/weixin-js-sdk/index.js'

import 'ant-design-vue/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'swiper/swiper-bundle.css'

import './components/common.css'
import './components/prompt-message/css/TipMessage.css'
import { str_add_space_format, number_format } from "./components/common.js"

Vue.config.productionTip = false;

Vue.use(VueRouter)
Vue.use(VueAwesomeSwiper)
Vue.use(VueAxios, axios)
Vue.use(Drawer)

// -------------------------全局统一的地址---------------------------
let url = "https://iot.roam.link/api";  // 生产
// let url = "http://m.roamwifi.net/api";  // 测试 
// let url = "http://192.168.120.151:8888/api";  // 测试
// let url = "http://10.10.14.54:8888/api";  //开发
Vue.prototype.baseUrl = url;
Vue.prototype.$wx=wx;
Vue.prototype.$modal=Modal;

//全剧过滤器, 对时间进行格式化
Vue.filter('str_format', str_add_space_format)
Vue.filter('num_format', number_format)

router.beforeEach((to, from, next) => {
    /* 根据路由的参数修改页面的title */
    if (to.name == 'countryDetail') {
        if (to.params.countryname) {
            document.title = to.params.countryname
        } else {
            document.title = to.meta.title
        }
    } else if (to.meta.title) {
        document.title = to.meta.title
    }

    /* 用户的openId  */
    if(to.query.code != null){
        window.addEventListener("popstate", ()=>{
            if(document.URL != "" && document.URL.indexOf("?") != -1){
                window.close();
                return;
            }
            localStorage.setItem("flag", 0)
            location.href = document.URL
        })
        localStorage.setItem("flag", 0)
        axios.post(url + "/user/code/wechatCode?code="+to.query.code)
        .then((res)=>{
            if(res.status == 200){
                if(res.data.code == 1 && res.data.data != null){
                    //数据库中不存在openId，去登录界面
                    localStorage.setItem("openId", res.data.data)
                    router.push({name: 'login'})
                }else if(res.data.code == 2 && res.data.data != null){
                    //数据库有openId,直接去用户想去的界面，设置全局token
                    localStorage.setItem("token", res.data.data.token)
                    localStorage.setItem("openId", res.data.data.openId)
                    //去原本路由的界面

                    var u = navigator.userAgent;
                    if(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1){//android终端
                        router.push({name: to.name}) 
                    }else if(!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){ //ios终端
                        location.href = window.location.href.split("?")[0]
                    }else{
                        router.push({name: to.name}) 
                    }
                    // location.href = window.location.href.split("?")[0]
                    // router.push({name: to.name})
                }
            }else{
                router.push({name: 'login'})
            }
        }).catch((err)=>{
            router.push({name: 'error'})
        });
    }else{
        next()
    }
})

var vm = new Vue({
    el: "#app",
    render: c => c(app),
    router,
})